#errorview_wrapper {
    margin-top: var(--nav-height-pc);
    height: calc(100vh - var(--footer-height-pc) - var(--nav-height-pc));
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffa500;
    flex-direction: column;
}

#errorview_image {
    width: 278px;
    height: 88px;
}

#errorview_text1 {
    margin-top: 29px;
    font-family: 'APPLE';
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
}

#errorview_text2 {
    margin-top: 10px;
    font-family: 'APPLE';
    font-size: 16px;
    color: #ffffff;
    text-align: center;
}

#errorview_button {
    margin-top: 10px;
    width: 200px;
    height: 48px;
    border-radius: 24px;
    border: solid 1px #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'APPLE';
    font-size: 18px;
    color: #ffffff;
}
