.buzmapintro_container {
    margin-top: var(--nav-height-pc);
    width: 100%;
    min-width: 1000px;
    height: calc(100vh - var(--nav-height-pc));
    display: flex;
    flex-direction: column;
    background-color: black;
    position: relative;

    align-items: center;

    /* overflow-x: hidden; */
    overflow: hidden;
}

.buzmapintro_wrapper {
    width: 1920px;
    height: 100%;

    position: relative;
}

#buzmapintro_bg_off {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 130px;

    width: 984px;
    height: 983px;

    background-image: url('./resources/buzmap-img-off.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#buzmapintro_bg_on {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 130px;

    width: 984px;
    height: 983px;

    background-image: url('./resources/buzmap-img-on.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#buzmapintro_text1 {
    position: absolute;
    top: calc(50% - 50px);
    left: 760px;

    font-family: 'APPLE';
    font-size: 32px;
    font-weight: bold;
    line-height: 1.25;
    color: #ffffff;
}

#buzmapintro_text1 b {
    color: #ffa500;
}

#buzmapintro_text2 {
    position: absolute;
    top: calc(50% - 50px);
    left: 761px;

    font-family: 'APPLE';
    font-size: 20px;
    font-weight: normal;
    line-height: 1.4;
    color: #ffffff;
}

#buzmapintro_text2 b {
    color: #ffa500;
}

#buzmapintro_lottie {
    position: absolute;
    left: 506px;
    top: 50%;
    transform: translateY(-50%);

    width: 230px;
    height: 230px;
}

#buzmapintro_button {
    cursor: pointer;

    position: absolute;
    left: 760px;
    top: calc(50% + 50px);

    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: 24px;
    padding-right: 24px;

    height: 48px;
    border-radius: 24px;
    background-color: #ffa500;

    font-family: 'APPLE';
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
}

@media (max-width: 1000px) {
    .buzmapintro_container {
        position: absolute;
        margin-top: var(--nav-height-mobile);
        flex: 1;
        min-width: 0px;
        height: calc(100% - var(--nav-height-mobile));
        overflow: auto;
        overflow-x: hidden;
    }

    .buzmapintro_wrapper {
        width: 100%;
        height: 100%;
        height: 507px;

        position: relative;
    }

    #buzmapintro_bg_off {
        position: absolute;
        top: calc(50% - 295px);
        transform: translateY(0%);
        left: -295px;

        width: 590px;
        height: 590px;

        background-image: url('./resources/buzmap-img-off-m.png');
    }

    #buzmapintro_bg_on {
        position: absolute;
        top: calc(50% - 295px);
        transform: translateY(0%);
        left: -295px;

        width: 590px;
        height: 590px;

        background-image: url('./resources/buzmap-img-on-m.png');
    }

    #buzmapintro_text1 {
        top: 50%;
        transform: translateY(-50%);
        left: 96px;

        font-size: 20px;
        word-break: keep-all;
    }

    #buzmapintro_text2 {
        top: 50%;
        transform: translateY(-50%);
        left: 96px;

        font-size: 14px;
        word-break: keep-all;
    }

    #buzmapintro_lottie {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);

        width: 136px;
        height: 136px;
    }

    #buzmapintro_button {
        cursor: pointer;

        position: fixed;
        left: 24px;
        width: calc(100% - 48px);
        top: auto;
        bottom: 24px;

        padding-left: 0px;
        padding-right: 0px;

        font-size: 16px;
    }
}
