:root {
    --footer-height-pc: 154px;
    --footer-height-mobile: 284px;
}

@import 'mixins';
@import 'variables';

#footer_container {
    height: $footer_height_pc;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    @media (max-width: $mobile_width) {
        height: $footer_height_mobile;
    }
}

#g_footer_wrapper {
    width: $mobile_width;
    flex: 1;
    display: flex;
    flex-direction: row;

    @media (max-width: $mobile_width) {
        display: none;
    }
}

#g_footer_wrapper_mobile {
    display: none;

    @media (max-width: $mobile_width) {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

#footer_logo {
    margin-top: 20px;
    width: 92px;
    height: 32px;
    display: flex;
}

#footer_buttons {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: $mobile_width) {
        position: relative;
        flex-direction: column;
        align-items: flex-start;
    }
}

#footer_copyright {
    @include buz_font(11px, #a0a0a0);
    display: flex;
    flex-direction: row;
    margin-top: 4px;

    @media (max-width: $mobile_width) {
        margin-top: 24px;
        margin-left: 24px;
        margin-right: 24px;
        font-size: 10px;
        margin-bottom: 20px;
    }
}

#footer_info {
    @include buz_font(11px, #a0a0a0);
    margin-top: 20px;
    width: 100%;

    line-height: 1.64;
}

#footer_copies {
    margin-top: 32px;
    flex: 1;
    margin-right: 26px;
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    @media (max-width: $mobile_width) {
        margin-top: 0px;
        margin-left: 24px;
        align-items: flex-start;
    }
}

.footer_copy {
    @include buz_font(14px, #777777);
    margin-right: 30px;
    text-align: center;
    text-decoration: none;

    transition: opacity $anim_duration;
    &:hover {
        opacity: 0.2;
    }

    @media (max-width: $mobile_width) {
        margin-top: 8px;
    }
}

@mixin footer_copy_colored($color) {
    @include buz_font(14px, $color);

    text-align: center;
    text-decoration: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80px;

    transition: opacity $anim_duration;
    &:hover {
        opacity: 0.2;
    }
}

.footer_copy_twitter {
    @include footer_copy_colored(#26aae7);

    @media (max-width: $mobile_width) {
        margin-top: 10px;
        width: auto;
    }
}

.footer_copy_naver {
    @include footer_copy_colored(#03c75a);

    position: absolute;
    width: 80px;
    top: 30px;
    right: 0px;

    @media (max-width: $mobile_width) {
        position: relative;
        width: auto;
        top: 0px;
        right: 0px;
        margin-top: 10px;
    }
}

#footer_contact_us {
    @include buz_font(14px, #ffa500);

    margin-top: 16px;
    margin-right: 0px;
    width: 100px;
    height: 48px;
    border-radius: 24px;
    border: solid 1px #ffa500;

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    text-align: center;

    text-decoration: none;

    transition: opacity $anim_duration;
    &:hover {
        opacity: 0.2;
    }

    @media (max-width: $mobile_width) {
        margin-top: 0px;
        margin-right: 24px;
        border-radius: 24px;
        border: solid 0px #ffa500;
        background-color: #ffa500;

        color: #ffffff;
    }
}
