#common_popup_bg {
    background-color: transparent;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 50000;

    display: flex;
    align-items: center;
    justify-content: center;
}
.common_popup_wrapper {
    position: relative;

    width: 280px;
    /* height: 180px; */
    opacity: 0.8;
    border-radius: 10px;
    background-color: #000000;
    transition-duration: 0.5s;
    transition-delay: 0s;
}

#common_popup_icon {
    width: 59px;
    height: 59px;
    margin-top: 41px;
    margin-left: calc(50% - 29.5px);
}

#common_popup_label {
    width: 90%;
    margin-left: 5%;
    margin-top: 32px;
    margin-bottom: 32px;
    font-family: 'APPLE';
    font-size: 16px;
    text-align: center;
    color: #ffffff;
}

.common_popup_wrapper2 {
    position: fixed;
    width: 280px;
    /* height: 180px; */
    border-radius: 10px;
    border: solid 1px rgba(45, 45, 45, 0.8);
    background-color: #ffffff;
}

#common_popup_label2 {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 34px;
    margin-bottom: 33px;
    font-family: 'APPLE';
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #2d2d2d;

    word-break: break-all;
}

#common_popup_button2 {
    cursor: pointer;

    margin-left: 80px;
    width: 120px;
    height: 35px;

    margin-bottom: 30px;
    border-radius: 5px;
    background-color: #ffa500;

    font-family: 'APPLE';
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
}

.common_popup_wrapper3 {
    position: fixed;
    width: 400px;
    /* height: 180px; */
    border-radius: 10px;
    border: solid 1px rgba(45, 45, 45, 0.8);
    background-color: #ffffff;
}

#common_popup_label3_1 {
    margin-top: 42px;
    margin-left: 40px;
    margin-right: 40px;
    font-family: 'APPLE';
    font-size: 26px;
    font-weight: bold;
    color: #2d2d2d;

    word-break: keep-all;
}

#common_popup_label3_2 {
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    word-break: break-all;
    font-family: 'APPLE';
    font-size: 20px;
    color: #2d2d2d;
}

#common_popup_label3_3 {
    margin-top: 6px;
    margin-left: 40px;
    margin-right: 40px;
    word-break: break-all;
    font-family: 'APPLE';
    font-size: 16px;
    line-height: 1.25;
    color: #2d2d2d;
}

#common_popup_label3_dummy {
    height: 1px;
    background-color: #c8c8c8;

    margin-top: 10px;
    margin-left: 40px;
    margin-right: 40px;
}

#common_popup_button3 {
    cursor: pointer;

    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;

    height: 48px;
    border-radius: 24px;
    background-color: #ffa500;

    font-family: 'APPLE';
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
}

@media (max-width: 1000px) {
    .common_popup_wrapper3 {
        position: fixed;
        width: 280px;
    }

    #common_popup_label3_1 {
        margin-top: 30px;
        margin-left: 30px;
        margin-right: 30px;
    }

    #common_popup_label3_2 {
        margin-top: 30px;
        margin-left: 30px;
        margin-right: 30px;
    }

    #common_popup_label3_3 {
        margin-top: 4px;
        margin-left: 30px;
        margin-right: 30px;
        font-size: 14px;
        line-height: 1.29;
    }

    #common_popup_label3_dummy {
        margin-left: 30px;
        margin-right: 30px;
    }

    #common_popup_button3 {
        margin-top: 30px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 30px;
    }
}
