.support_container {
    margin-top: var(--nav-height-pc);
    width: 100%;
    min-width: 1000px;
    /* 100% - nav - footer */
    display: flex;
    background-color: #ffffff;
    justify-content: center;
}

#support_bg {
    display: flex;
    flex-direction: column;
    width: 1000px;
    align-items: center;
}

#support_title {
    width: 100%;
    margin-top: var(--nav-height-pc);

    font-family: 'APPLE';
    font-size: 40px;
    font-weight: bold;
    color: #2d2d2d;
}

#support_title2 {
    width: 100%;
    margin-top: 4px;
    font-family: 'APPLE';
    font-size: 28px;
    color: #2d2d2d;
}

#support_subtitle {
    width: 100%;
    margin-top: 10px;

    margin-bottom: 30px;

    font-family: 'APPLE';
    font-size: 14px;
    color: #a0a0a0;
}

#support_itemview_collapsed {
    width: 1000px;
    height: 90px;
    border-radius: 10px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    margin-bottom: 20px;

    display: flex;
    flex-direction: 'row';
    align-items: center;
}

#support_itemview_collapsed:hover {
    background-color: #fafafa;
}

.support_itemview_title {
    flex: 1;
    margin-left: 20px;
    font-family: 'APPLE';
    font-size: 20px;
    font-weight: bold;
    color: #2d2d2d;
}

#support_itemview_expanded {
    margin-bottom: 20px;
    width: 1000px;
    border-radius: 10px;
    border: solid 1px #e6e6e6;
    background-color: #fafafa;
}

#support_itemview_expanded_body {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'APPLE';
    font-size: 16px;
    line-height: 1.5;
    color: #2d2d2d;
}

#support_itemview_expanded_body ol {
    list-style-type: circle;
    padding-left: 20px;
}

#support_itemview_expanded_body li {
    margin-bottom: 20px;
}

.support_itemview_expanded_image_pc {
    margin-top: 28px;
    width: 508px;
    height: 313px;

    margin-left: 50%;
    transform: translateX(-50%);

    margin-bottom: 28px;
}

.support_itemview_expanded_image_mobile {
    display: none;
}

.support_icon {
    width: 34px;
    height: 34px;
    margin-right: 20px;
    margin-left: 20px;
}

#support_bottom {
    height: 129px;
}

@media (max-width: 1000px) {
    .support_container {
        min-width: 0px;
        margin-top: var(--nav-height-mobile);
    }

    #support_bg {
        width: 100%;
    }

    #support_title {
        margin-top: 33px;
        width: calc(100% - 48px);
        margin-left: 24px;
        margin-right: 24px;

        font-size: 28px;
    }

    #support_title2 {
        margin-top: 4px;
        width: calc(100% - 48px);
        margin-left: 24px;
        margin-right: 24px;

        font-size: 18px;
    }

    #support_subtitle {
        width: calc(100% - 48px);
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 1.57;
    }

    #support_itemview_collapsed {
        width: calc(100% - 48px);
    }

    #support_itemview_expanded {
        width: calc(100% - 48px);
    }

    .support_itemview_title {
        font-size: 16px;
    }

    #support_itemview_expanded_body {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 1.5;
    }

    #support_bottom {
        height: 45px;
    }

    .support_itemview_expanded_image_pc {
        display: none;
    }

    .support_itemview_expanded_image_mobile {
        display: flex;
        margin-top: 28px;
        width: calc(100% - 48px);
        max-width: 287px;
        height: auto;

        margin-left: 50%;
        transform: translateX(-50%);

        margin-bottom: 28px;
    }
}
