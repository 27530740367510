#maincard4_bg {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    /* align-items: center; */
}

#maincard4_wrapper {
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 1920px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    flex: 1;
}

#maincard4_bg1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc((265 / 1080) * 100vh);
    width: calc((573 / 1080) * 100vh);
    height: calc((550 / 1080) * 100vh);

    background-image: url('./resources/MainCard4/buzmap-img-intro.png');
    background-repeat: no-repeat;
    background-size: contain;

    transition-duration: 0.5s;
}

#maincard4_copy {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc((324 / 1080) * 100vh);

    font-family: 'APPLE';
    font-size: calc((40 / 1080) * 100vh);
    font-weight: bold;
    text-align: center;
    color: white;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard4_copy b {
    color: #ffa500;
}

#maincard4_copy_mobile {
    display: none;
}

@media (max-width: 1000px) {
    #maincard4_wrapper {
        width: 100%;
        overflow: hidden;
        align-items: center;
        justify-content: center;
    }

    #maincard4_bg1 {
        position: absolute;
        left: 50%;
        top: calc(50% - 220px);
        width: 459px;
        height: 440px;
    }

    #maincard4_copy {
        display: none;
    }

    #maincard4_copy_mobile {
        display: flex;

        position: absolute;
        width: 100%;
        justify-content: center;
        bottom: calc(50% + 60px);

        font-family: 'APPLE';
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: white;

        opacity: 0;
        transition-duration: 0.5s;
    }

    #maincard4_copy_mobile b {
        color: #ffa500;
    }
}
