#whatisbuz_wrapper {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#whatisbuz_content {
    width: 1000px;
    padding-top: 140px;
    padding-bottom: 240px;
}

.whatisbuz_section {
    position: relative;
    height: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.whatisbuz_section_text {
    position: relative;
    /* height: 24px; */
    font-size: 48px;
    color: #ffa500;
    font-family: 'APPLE';
    font-weight: bold;
}

.whatisbuz_section_line {
    display: none;
    flex: 1;
    margin-left: 10px;
    height: 1px;
    background-color: #e6e6e6;
}

#whatisbuz_section2 {
    margin-top: 330px;
    width: 160px;
}

#whatisbuz_content1 {
    margin-top: 90px;
    width: auto;
    display: flex;
    flex-direction: row;
}

#whatisbuz_content1_1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#whatisbuz_content1_1_1 {
}

#whatisbuz_content1_1_2 {
    width: 632px;
    margin-top: 12px;
}

#whatisbuz_content1_2 {
    margin-top: 35px;
    width: 300px;
    height: 137px;
}

.whatisbuz_content_title {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.25;
    color: #2d2d2d;
    font-family: 'APPLE';
    display: flex;
}

.whatisbuz_content_title_pc {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.2;
    color: #2d2d2d;
    font-family: 'APPLE';
    display: flex;
}

.whatisbuz_content_title_mobile {
    display: none;
}

#whatisbuz_content2_1_1 b {
    color: #ffa500;
}

.whatisbuz_content_subtitle {
    font-size: 28px;
    line-height: 1.43;
    color: #2d2d2d;
    font-family: 'APPLE';

    word-break: keep-all;
}

.whatisbuz_content_subsubtitle {
    font-size: 20px;
    line-height: 1.4;
    color: #2d2d2d;
    font-family: 'APPLE';
}

#whatisbuz_content2 {
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

#whatisbuz_content2_1 {
    flex: 1;
}

#whatisbuz_content2_1_2 {
    margin-top: 10px;
}

#whatisbuz_content2_2_images1 {
    width: 510px;
    height: 179px;
}

#whatisbuz_content2_2_images1_mobile {
    display: none;
}

#whatisbuz_content5 {
    margin-top: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#whatisbuz_content5_2 {
    width: 100%;
    margin-top: 90px;
    position: relative;
    display: flex;
    flex-direction: row;
}

#whatisbuz_content5_2_1 {
    position: relative;
    width: 300px;
    height: 366px;
}

#whatisbuz_content5_2_2 {
    position: relative;
    width: 300px;
    height: 366px;
    margin-left: 50px;
}

#whatisbuz_content5_2_3 {
    position: relative;
    width: 300px;
    height: 366px;
    margin-left: 50px;
}

.whatisbuz_content5_2_image {
    position: absolute;
    width: 100%;
    height: 100%;
}

.whatisbuz_content5_2_text {
    position: relative;
    margin-top: 208px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #2d2d2d;
    font-family: 'APPLE';
}

.whatisbuz_content5_2_text2 {
    margin-top: 8.3px;
    height: 108.3px;

    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;

    display: flex;
    /* justify-content: center; */

    /* text-align: center; */
    word-break: break-all;
    font-size: 14px;
    color: #2d2d2d;
    font-family: 'APPLE';
}

#whatisbuz_content3 {
    margin-top: 160px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

#whatisbuz_content3_1 {
    flex: 1;
}

#whatisbuz_content3_1_2 {
    margin-top: 10px;
}

#whatisbuz_content3_1_3 {
    margin-top: 10px;
}

#whatisbuz_content3_1_4 {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
}

.whatisbuz_content3_1_4_images {
    width: 220px;
    height: 83px;
}

#whatisbuz_content3_1_4_images2 {
    margin-left: 20px;
}

#whatisbuz_content3_1_4_images3 {
    margin-left: 20px;
}

#whatisbuz_content3_2 {
    margin-left: -73px;
    width: 370px;
    height: 370px;
}

#whatisbuz_content4 {
    margin-top: 38px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#whatisbuz_content4_1 {
    display: flex;
    flex-direction: column;
    flex: 1;
}

#whatisbuz_content4_1_1 {
    margin-top: 10px;
}

#whatisbuz_content4_1_3 {
    margin-top: 8px;
    height: 30px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 5px;
    background-color: #2c2d33;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'APPLE';
    font-size: 14px;
    text-align: center;
    color: #ffffff;

    text-decoration: none;
}

#whatisbuz_content4_2 {
    margin-top: 40px;
    border-radius: 10px;
    border: solid 1px #e6e6e6;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

#whatisbuz_content4_2_1 {
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: row;
}

.whatisbuz_content4_2_1_image {
    margin-top: 30px;
    width: 100px;
    height: 100px;
}

#whatisbuz_content4_2_1_image1 {
    margin-left: 30px;
    background-image: url('./resources/ico-baas-01-off.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#whatisbuz_content4_2_1_image1:hover {
    background-image: url('./resources/ico-baas-01-on.png');
}

#whatisbuz_content4_2_1_image1_checked {
    margin-left: 30px;
    background-image: url('./resources/ico-baas-01-on.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#whatisbuz_content4_2_1_image2 {
    margin-left: 49px;
    background-image: url('./resources/ico-baas-02-off.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#whatisbuz_content4_2_1_image2:hover {
    background-image: url('./resources/ico-baas-02-on.png');
}

#whatisbuz_content4_2_1_image2_checked {
    margin-left: 49px;
    background-image: url('./resources/ico-baas-02-on.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#whatisbuz_content4_2_1_image3 {
    margin-left: 49px;
    background-image: url('./resources/ico-baas-03-off.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#whatisbuz_content4_2_1_image3:hover {
    background-image: url('./resources/ico-baas-03-on.png');
}

#whatisbuz_content4_2_1_image3_checked {
    margin-left: 49px;
    background-image: url('./resources/ico-baas-03-on.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#whatisbuz_content4_2_1_image4 {
    margin-left: 49px;
    background-image: url('./resources/ico-baas-04-off.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#whatisbuz_content4_2_1_image4:hover {
    background-image: url('./resources/ico-baas-04-on.png');
}

#whatisbuz_content4_2_1_image4_checked {
    margin-left: 49px;
    background-image: url('./resources/ico-baas-04-on.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#whatisbuz_content4_2_1_image5 {
    margin-left: 49px;
    background-image: url('./resources/ico-baas-05-off.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#whatisbuz_content4_2_1_image5:hover {
    background-image: url('./resources/ico-baas-05-on.png');
}

#whatisbuz_content4_2_1_image5_checked {
    margin-left: 49px;
    background-image: url('./resources/ico-baas-05-on.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.whatisbuz_content4_2_1_dash {
    width: 12px;
    height: 18px;
    margin-top: 74px;
    margin-left: 49px;
}

#whatisbuz_content4_2_2 {
    width: 100%;
    flex: 1;
    background-color: #fcfcfc;
    border-top: solid 1px #e6e6e6;
}

#whatisbuz_baascontent1_1 {
    margin-left: 50px;
    margin-top: 40px;
    font-size: 28px;
    color: #2d2d2d;
    font-family: 'APPLE';
}

#whatisbuz_baascontent1_2 {
    display: flex;
    margin-top: 30px;
    margin-left: 50px;
    width: 695px;
    height: 323px;
}

#whatisbuz_baascontent1_3 {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.25;
    color: #29313a;
    font-family: 'APPLE';
    word-break: keep-all;
}

#whatisbuz_baascontent2_1 {
    margin-left: 50px;
    margin-top: 40px;
    font-size: 28px;
    color: #2d2d2d;
    font-family: 'APPLE';
}

#whatisbuz_baascontent2_2 {
    margin-left: 50px;
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.25;
    color: #29313a;
    font-family: 'APPLE';
    word-break: keep-all;
}

#whatisbuz_baascontent2_3 {
    display: flex;
    margin-top: 30px;
    margin-left: 50px;
    margin-bottom: 114px;
    width: 764px;
    height: 213px;
}

#whatisbuz_baascontent3_1 {
    margin-left: 50px;
    margin-top: 40px;
    font-size: 28px;
    color: #2d2d2d;
    font-family: 'APPLE';
}

#whatisbuz_baascontent3_2 {
    margin-top: 10px;
    margin-left: 50px;
    width: 641px;
    height: 209px;
}

#whatisbuz_baascontent3_3 {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.25;
    color: #29313a;
    font-family: 'APPLE';
    word-break: keep-all;
}

#whatisbuz_baascontent3_4 {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-left: 50px;
    padding-bottom: 42px;
    position: relative;
}

#whatisbuz_baascontent3_4_1 {
    position: relative;
}

#whatisbuz_baascontent3_4_image1 {
    width: 300px;
    height: 83px;
}

#whatisbuz_baascontent3_4_2 {
    margin-left: 10px;
    position: relative;
}

#whatisbuz_baascontent3_4_image2 {
    width: 300px;
    height: 83px;
}

#whatisbuz_baascontent3_4_3 {
    margin-left: 10px;
    position: relative;
}

#whatisbuz_baascontent3_4_image3 {
    width: 300px;
    height: 83px;
}

.whatisbuz_baascontent3_4_text {
    position: absolute;
    left: 107px;
    top: 0px;
    height: 100%;
    font-size: 16px;
    color: #29313a;
    font-family: 'APPLE';
    word-break: keep-all;
    display: flex;
    align-items: center;
}

#whatisbuz_baascontent4_1 {
    margin-left: 50px;
    margin-top: 40px;
    font-size: 28px;
    color: #2d2d2d;
    font-family: 'APPLE';
    word-break: keep-all;
}

#whatisbuz_baascontent4_2 {
    margin-top: 37px;
    padding-bottom: 111px;
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#whatisbuz_baascontent4_2_image {
    width: 333px;
    height: 318px;
}

#whatisbuz_baascontent4_2_text {
    margin-left: 60px;
    margin-right: 50px;
    font-size: 16px;
    line-height: 1.25;
    color: #29313a;
    font-family: 'APPLE';
    /* 단어단위 */
    word-break: keep-all;
}

#whatisbuz_baascontent5_1 {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 40px;
    font-size: 28px;
    color: #2d2d2d;
    font-family: 'APPLE';
}

#whatisbuz_baascontent5_2 {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
    font-size: 16px;
    line-height: 1.25;
    color: #29313a;
    font-family: 'APPLE';
    word-break: keep-all;
}

#whatisbuz_baascontent5_3 {
    margin-top: 30px;
    display: flex;
    margin-left: 40px;
    padding-bottom: 33px;
    width: 434px;
    height: 295px;
}

#whatisbuz_baascontent5_4 {
    display: none;
}

.whatisbuz_dummy {
    height: 120px;
}

#whatisbuz_banner {
    display: none;
}

#whatisbuz_floating {
    cursor: pointer;
    width: 229px;
    height: 236px;
    right: 30px;
    bottom: 184px;
    position: fixed;
}

@media (max-width: 1000px) {
    .whatisbuz_section_line {
        display: flex;
    }

    #whatisbuz_wrapper {
        width: calc(100% - 48px);
        padding-left: 24px;
        padding-right: 24px;
    }

    #whatisbuz_content {
        padding-top: 24px;
        padding-bottom: 0px;
        width: 100%;
    }

    .whatisbuz_section {
        height: 17px;
    }

    .whatisbuz_section_text {
        height: 17px;
        font-size: 14px;
    }

    #whatisbuz_content1 {
        margin-top: 40px;
        height: auto;
        flex-direction: column;
    }

    #whatisbuz_content1_1 {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #whatisbuz_content1_1_1 {
    }

    #whatisbuz_content1_1_2 {
        width: auto;
        margin-top: 10px;
    }

    #whatisbuz_content1_2 {
        margin-top: 40px;
        margin-left: 50%;
        transform: translateX(-50%);
        width: 300px;
        height: auto;
    }

    .whatisbuz_content_title {
        font-size: 28px;
        line-height: 1.29;
    }

    .whatisbuz_content_title_pc {
        display: none;
    }

    .whatisbuz_content_title_mobile {
        display: flex;

        font-size: 28px;
        line-height: 1.29;
        font-weight: bold;
        color: #2d2d2d;
        font-family: 'APPLE';
        display: flex;
    }

    .whatisbuz_content_subtitle {
        font-size: 20px;
        line-height: 1.43;
        word-break: keep-all;
    }

    .whatisbuz_content_subsubtitle {
        font-size: 16px;
        line-height: 1.4;
        color: #2d2d2d;
        font-family: 'APPLE';
        word-break: keep-all;
    }

    #whatisbuz_content2 {
        margin-top: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #whatisbuz_content2_1_2 {
        margin-top: 10px;
    }

    #whatisbuz_content2_2_images1 {
        display: none;
    }

    #whatisbuz_content2_2_images1_mobile {
        display: flex;
        margin-top: 30px;
        width: 327px;
        height: auto;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    #whatisbuz_content5 {
        margin-top: 40px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #whatisbuz_content5_2 {
        margin-top: 0px;
        height: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #whatisbuz_content5_2_1 {
        width: 327px;
        height: 377px;
    }

    #whatisbuz_content5_2_2 {
        width: 327px;
        height: 377px;
        margin-top: 24px;
        margin-left: 0px;
    }

    #whatisbuz_content5_2_3 {
        width: 327px;
        height: 377px;
        margin-top: 24px;
        margin-left: 0px;
    }

    .whatisbuz_content5_2_text {
        margin-top: 215px;
        font-size: 16px;
    }

    .whatisbuz_content5_2_text2 {
        margin-top: 10px;
        height: 130px;

        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 14px;
    }

    #whatisbuz_content3 {
        margin-top: 60px;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    #whatisbuz_content3_1_2 {
        margin-top: 10px;
    }

    #whatisbuz_content3_1_3 {
        margin-top: 10px;
    }

    #whatisbuz_content3_1_4 {
        flex-direction: column;
    }

    #whatisbuz_content3_1_4_images1 {
        margin-left: calc(50% - 50px);
        transform: translateX(-50%);
    }

    #whatisbuz_content3_1_4_images2 {
        margin-top: 20px;
        margin-left: calc(50% - 50px);
        transform: translateX(-50%);
    }

    #whatisbuz_content3_1_4_images3 {
        margin-top: 20px;
        margin-left: calc(50% - 50px);
        transform: translateX(-50%);
    }

    #whatisbuz_content3_2 {
        /* position: absolute; */
        width: 200px;
        height: auto;
        margin-left: calc(50% - 30px);
        z-index: 10;
        margin-top: -210px;
    }

    #whatisbuz_content4 {
        margin-top: 60px;
    }

    #whatisbuz_content4_2 {
        border-radius: 0px;
        width: calc(100% + 48px);
        margin-left: -24px;
        border: solid 0px #e6e6e6;
        border-top: solid 1px #e6e6e6;
    }

    #whatisbuz_content4_2_1 {
        width: 100%;
        height: 102px;
        display: flex;
        flex-direction: row;
        overflow-x: auto;

        /* overflow-x: scroll; */
    }

    .whatisbuz_content4_2_1_image {
        margin-top: 24px;
        width: 58px;
        height: 58px;
    }

    #whatisbuz_content4_2_1_image1 {
        margin-left: 22px;
    }

    #whatisbuz_content4_2_1_image1_checked {
        margin-left: 22px;
    }

    #whatisbuz_content4_2_1_image2 {
        margin-left: 10px;
    }

    #whatisbuz_content4_2_1_image2_checked {
        margin-left: 10px;
    }

    #whatisbuz_content4_2_1_image3 {
        margin-left: 10px;
    }

    #whatisbuz_content4_2_1_image3_checked {
        margin-left: 10px;
    }

    #whatisbuz_content4_2_1_image4 {
        margin-left: 10px;
    }

    #whatisbuz_content4_2_1_image4_checked {
        margin-left: 10px;
    }

    #whatisbuz_content4_2_1_image5 {
        margin-left: 10px;
        margin-right: 22px;
    }

    #whatisbuz_content4_2_1_image5_checked {
        margin-left: 10px;
        margin-right: 22px;
    }

    .whatisbuz_content4_2_1_dash {
        display: none;
    }

    #whatisbuz_baascontent1_1 {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 24px;
        font-size: 20px;
    }

    #whatisbuz_baascontent1_2 {
        display: flex;
        margin-top: 20px;
        width: calc(100% - 48px);
        height: auto;

        margin-left: 50%;
        transform: translateX(-50%);
        max-width: 327px;
    }

    #whatisbuz_baascontent1_3 {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 10px;
        margin-bottom: 246px;
        font-size: 14px;
        line-height: 1.8;
    }

    #whatisbuz_baascontent2_1 {
        margin-left: 24px;
        margin-top: 24px;
        font-size: 20px;
        color: #2d2d2d;
        font-family: 'APPLE';
    }

    #whatisbuz_baascontent2_2 {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 10px;
        font-size: 14px;
        line-height: 1.8;
    }

    #whatisbuz_baascontent2_3 {
        display: flex;
        margin-top: 37px;
        margin-bottom: 253px;
        width: calc(100% - 48px);
        height: auto;

        margin-left: 50%;
        transform: translateX(-50%);
        max-width: 327px;
    }

    #whatisbuz_baascontent3_1 {
        margin-left: 24px;
        margin-top: 24px;
        font-size: 20px;
    }

    #whatisbuz_baascontent3_2 {
        margin-top: 20px;
        width: calc(100% - 48px);
        height: auto;

        margin-left: 50%;
        transform: translateX(-50%);
        max-width: 327px;
    }

    #whatisbuz_baascontent3_3 {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 10px;
        margin-right: 24px;
        font-size: 14px;
        line-height: 1.8;
    }

    #whatisbuz_baascontent3_4 {
        flex-direction: column;
        margin-top: 20px;
        margin-left: 0px;
        padding-bottom: 25px;
        position: relative;
        align-items: center;
    }

    #whatisbuz_baascontent3_4_1 {
        position: relative;
    }

    #whatisbuz_baascontent3_4_image1 {
        width: 300px;
        height: 83px;
    }

    #whatisbuz_baascontent3_4_2 {
        margin-top: 10px;
        margin-left: 0px;
    }

    #whatisbuz_baascontent3_4_image2 {
        width: 300px;
        height: 83px;
    }

    #whatisbuz_baascontent3_4_3 {
        margin-top: 10px;
        margin-left: 0px;
    }

    #whatisbuz_baascontent3_4_image3 {
        width: 300px;
        height: 83px;
    }

    #whatisbuz_baascontent4_1 {
        margin-left: 24px;
        margin-top: 24px;
        font-size: 20px;
    }

    #whatisbuz_baascontent4_2 {
        margin-top: 20px;
        padding-bottom: 36px;
        margin-left: 0px;
        flex-direction: column;
        align-items: center;
    }

    #whatisbuz_baascontent4_2_image {
        width: calc(100% - 48px);
        height: auto;

        /* margin-left: 50%;
        transform: translateX(-50%); */
        max-width: 216px;
    }

    #whatisbuz_baascontent4_2_text {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 40px;
        font-size: 14px;
        line-height: 1.8;
    }

    #whatisbuz_baascontent5_1 {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 24px;
        font-size: 20px;
    }

    #whatisbuz_baascontent5_2 {
        margin-top: 10px;
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 165px;
        font-size: 14px;
        line-height: 1.8;
    }

    #whatisbuz_baascontent5_3 {
        display: none;
    }

    #whatisbuz_baascontent5_4 {
        display: flex;
        margin-top: 20px;
        width: calc(100% - 48px);
        height: auto;

        margin-left: 50%;
        transform: translateX(-50%);
        max-width: 216px;
    }

    .whatisbuz_dummy {
        height: 60px;
    }

    #whatisbuz_floating {
        display: none;
    }

    #whatisbuz_banner {
        cursor: pointer;
        margin-top: var(--nav-height-mobile);
        display: inline;
        width: 100vw;
        height: 120px;
        background-color: #f7ece1;
    }

    #whatisbuz_banner_image {
        margin-left: 50%;
        transform: translateX(-50%);

        width: 375px;
        height: 120px;

        position: relative;

        background-image: url('./resources/img-what-01.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    #whatisbuz_banner_text {
        position: absolute;
        left: 22px;
        top: 76px;

        width: 100px;
        height: 26px;
        border-radius: 13px;
        border: solid 1px #60524b;

        display: flex;
        align-items: center;
        justify-content: center;

        font-family: 'APPLE';
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.28px;
        color: #60524b;
    }
}
