#agreement_content2_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: center;
}

#agreement_content2_wrapper {
    padding-left: 60px;
    padding-top: 121px;
    width: 640px;
    padding-right: 280px;

    padding-bottom: 100px;
}

.agreement_content2_template01 {
    font-family: 'APPLE';
    font-weight: bold;
    font-size: 24px;
    line-height: 1.5;
    color: #2d2d2d;
    margin-bottom: 30px;
}

.agreement_content2_template02 {
    margin-top: 30px;
    font-family: 'APPLE';
    font-weight: bold;
    font-size: 18px;
    line-height: 1.57;
    color: #2d2d2d;
    margin-bottom: 30px;
}

.agreement_content2_template03 {
    list-style-type: decimal;
    font-family: 'APPLE';
    font-weight: normal;
    font-size: 14px;
    line-height: 1.57;
    color: #2d2d2d;
    margin-bottom: 6px;
}

.agreement_content2_template03_2 {
    list-style-type: circle;
    font-family: 'APPLE';
    font-weight: normal;
    font-size: 14px;
    line-height: 1.57;
    color: #2d2d2d;
    margin-bottom: 6px;
}

.agreement_content2_template04 {
    list-style-type: lower-alpha;
    /* margin-left: 10px; */
    font-family: 'APPLE';
    font-weight: normal;
    font-size: 14px;
    line-height: 1.57;
    color: #2d2d2d;
    margin-bottom: 6px;
}

.agreement_content2_template05 {
    list-style-type: lower-roman;
    /* margin-left: 20px; */
    font-family: 'APPLE';
    font-weight: normal;
    font-size: 14px;
    line-height: 1.57;
    color: #2d2d2d;
    margin-bottom: 6px;
}

#agreement_content2_table {
    border-top: solid 1px #e6e6e6;
    display: flex;
    flex-direction: column;
}

.agreement_content2_column {
    display: flex;
    flex-direction: row;
}

.agreement_content2_table_title {
    border-bottom: solid 1px #e6e6e6;
    border-left: solid 1px #e6e6e6;
    min-height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-left: 19px;
    padding-right: 19px;

    font-family: 'APPLE';
    font-size: 14px;
    color: #2d2d2d;
}

.agreement_content2_table_content {
    border-bottom: solid 1px #e6e6e6;
    border-left: solid 1px #e6e6e6;
    min-height: 25px;
    display: flex;
    align-items: center;
    flex: 1;

    padding-left: 19px;
    padding-right: 19px;
    font-family: 'APPLE';
    font-size: 12px;
    line-height: 2.17;
    color: #2d2d2d;
}

@media (max-width: 1000px) {
    #agreement_content2_container {
        flex-direction: column;
    }

    #agreement_content2_wrapper {
        padding: 24px;
        width: calc(100% - 48px);
    }
}
