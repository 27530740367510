#buzfriends_container {
    margin-top: var(--nav-height-pc);
    background-color: #ffa500;
    position: relative;
    min-height: calc(100vh - var(--nav-height-pc) - var(--footer-height-pc));

    display: flex;
    flex-direction: column;

    overflow: hidden;
}

#buzfriends_wrapper {
    width: 1000px;
    margin-left: 50%;
    transform: translateX(-50%);
    position: relative;
    display: flex;
    flex-direction: column;

    align-items: center;
}
#buzfriends_bg3 {
    position: absolute;
    left: -219px;
    top: 43px;

    width: 267px;
    height: 267px;
    border-radius: 133.5px;
    background-color: #ffc04d;
    opacity: 0.5;

    display: flex;
    justify-content: center;
    align-items: center;
}

#buzfriends_bg3_1 {
    width: 207px;
    height: 207px;
    border-radius: 103.5px;
    background-color: #ffa500;
}

#buzfriends_bg4 {
    position: absolute;
    right: -230px;
    top: 485px;

    width: 214px;
    height: 215px;

    background-image: url('./resources/friends-b-3.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.buzfriends_img {
    margin-top: 257px;
    width: 416px;
    height: 254px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('./resources/friendend-img-01.png');
}

.buzfriend_title1 {
    margin-top: 85px;

    font-family: 'APPLE';
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.buzfriend_title2 {
    margin-top: 85px;

    font-family: 'APPLE';
    font-size: 24px;
    font-weight: bold;
    text-align: center;

    display: none;
}

.buzfriend_title3 {
    margin-top: 28px;

    font-family: 'APPLE';
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 28px;
}

@media (max-width: 1000px) {
    #buzfriends_container {
        margin-top: var(--nav-height-mobile);
        min-height: 0px;
        background-color: #ffa500;
        position: relative;
    }

    #buzfriends_wrapper {
        width: 100%;
        max-width: 320px;
        /* margin-left: 50%; */
        position: relative;
    }

    #buzfriends_bg3 {
        display: none;
    }

    #buzfriends_bg4 {
        display: none;
    }

    .buzfriend_title1 {
        display: none;
    }

    .buzfriend_title2 {
        display: flex;
    }
}
