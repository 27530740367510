.webtoon_container {
    position: fixed;
    z-index: 20001;
    width: 100vw;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7ece1;

    overflow-x: hidden;
    overflow-y: scroll;
}

#webtoon_wrapper {
    margin-top: 0px;
    background-color: #f7ece1;
    position: relative;
    width: 100%;
    height: auto;
}

#webtoon_header {
    width: 100%;
    height: 440px;
    background-color: #ffa500;
}

#webtoon_header_copy {
    position: absolute;
    left: 30px;
    top: 231px;

    font-family: 'APPLE';
    font-size: 24px;
    font-weight: bold;
    line-height: 1.33;
    color: #ffffff;
}

#webtoon_header_copy b {
    color: #2d2d2d;
}

#webtoon_close_pc {
    cursor: pointer;
    position: fixed;
    top: 40px;
    right: 40px;
    width: 48px;
    height: 48px;

    z-index: 10;
}

#webtoon_close_mobile {
    display: none;
}

#webtoon_header_image {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 1000px;
    height: 520px;
    background-image: url('./resources/header.png');
    background-repeat: no-repeat;
    background-size: cover;
}

#toons {
    margin-top: 180px;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 762px;
    max-width: 751px;
}

#toons #toon1 {
    margin-top: 0px;
    width: 100%;
    height: 661px;
    position: relative;
}

#toons #toon1_1 {
    position: absolute;
    left: 81px;
    top: 82px;
    width: 535px;
    height: auto;
}

#toons #toon1_2 {
    position: absolute;
    left: 365px;
    top: 246px;
    width: 368px;
    height: auto;
    opacity: 0;
    transform: scaleX(0.95) scaleY(0.95) translateX(-10%) translateY(-10%);
}

#toons #toon1_3 {
    position: absolute;
    left: 108px;
    top: 0px;
    width: 185px;
    height: auto;
    opacity: 0;
}

#toons #toon1_4 {
    position: absolute;
    left: 246px;
    top: 232px;
    width: 237px;
    height: auto;
    opacity: 0;
}

#toons #toon1_4_desc {
    position: absolute;
    left: 246px;
    top: 232px;
    width: 237px;
    height: 210px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: 'kyobofont';
    font-size: 38px;
    text-align: center;
    color: #584935;
}

#toons #toon1_5_desc {
    position: absolute;
    left: 0px;
    top: 634px;
    width: 100%;
    height: 27px;
    opacity: 0;
    text-align: center;

    font-family: 'kyobofont';
    font-size: 28px;
    color: #3a2929;
}

#toons #toon2 {
    width: 100%;
    height: 949px;
    margin-top: 215px;
    position: relative;
}

#toons #toon2_1 {
    position: absolute;
    left: 56px;
    top: 0px;
    width: 343px;
    height: auto;
}

#toons #toon2_2 {
    position: absolute;
    left: 404px;
    top: 276px;
    width: 340px;
    height: auto;
}

#toons #toon2_3 {
    position: absolute;
    left: 87px;
    top: 460px;
    width: 475px;
    height: auto;
    opacity: 0;
    transform: scaleX(0.95) scaleY(0.95) translateY(-10%);
}

#toons #toon2_4 {
    position: absolute;
    left: 31px;
    top: 401px;
    width: 255px;
    height: auto;
    opacity: 0;
}

#toons #toon2_4_desc {
    position: absolute;
    left: 31px;
    top: 401px;
    width: 255px;
    height: 255px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: 'kyobofont';

    font-size: 40px;
    text-align: center;
    color: #584935;
}

#toons #toon3 {
    width: 100%;
    height: 1023px;
    margin-top: 232px;
    position: relative;
}

#toons #toon3_1 {
    position: absolute;
    left: 227px;
    top: 54px;
    width: 413px;
    height: auto;
}

#toons #toon3_2 {
    position: absolute;
    left: 160px;
    top: 453px;
    width: 521px;
    height: auto;
    opacity: 0;
}

@keyframes bounce {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    70% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

#toons #toon3_3 {
    position: absolute;
    left: 83px;
    top: 0px;
    width: 263px;
    height: auto;
    opacity: 0;
}

#toons #toon3_4 {
    position: absolute;
    left: 184px;
    top: 238px;
    width: 199px;
    height: auto;
    opacity: 0;
}

#toons #toon3_4_desc {
    position: absolute;
    left: 184px;
    top: 250px;
    width: 199px;
    height: 180px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: 'kyobofont';
    font-size: 40px;
    text-align: center;
    color: #584935;
}

#toons #toon3_5 {
    position: absolute;
    left: 501px;
    top: 481px;
    width: 250px;
    height: auto;
    opacity: 0;
}

#toons #toon3_5_desc {
    position: absolute;
    left: 501px;
    top: 481px;
    width: 250px;
    height: 220px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: 'kyobofont';
    font-size: 40px;
    text-align: center;
    color: #584935;
}

#toons #toon3_6_desc {
    position: absolute;
    left: 0px;
    top: 997px;
    width: 100%;
    height: 26px;
    opacity: 0;

    font-family: 'kyobofont';
    font-size: 28px;
    text-align: center;
    color: #3a2929;
}

#toons #toon4 {
    width: 100%;
    height: 1803px;
    margin-top: 122px;
    position: relative;
}

#toons #toon4_1 {
    position: absolute;
    left: 320px;
    top: 0px;
    width: 156px;
    height: auto;
}

#toons #toon4_2 {
    position: absolute;
    left: 162px;
    top: 262px;
    width: 106px;
    height: auto;
    opacity: 0;
}

#toons #toon4_3 {
    position: absolute;
    left: 303px;
    top: 226px;
    width: 194px;
    height: auto;
}

#toons #toon4_4 {
    position: absolute;
    left: 525px;
    top: 248px;
    width: 106px;
    height: auto;
    opacity: 0;
}

#toons #toon4_5 {
    position: absolute;
    left: 58px;
    top: 299px;
    width: 80px;
    height: auto;
    opacity: 0;
}

#toons #toon4_6 {
    position: absolute;
    left: 101px;
    top: 396px;
    width: 80px;
    height: auto;
    opacity: 0;
}

#toons #toon4_7 {
    position: absolute;
    left: 205px;
    top: 415px;
    width: 80px;
    height: auto;
    opacity: 0;
}

#toons #toon4_8 {
    position: absolute;
    left: 320px;
    top: 457px;
    width: 80px;
    height: auto;
    opacity: 0;
}

#toons #toon4_9 {
    position: absolute;
    left: 453px;
    top: 444px;
    width: 80px;
    height: auto;
    opacity: 0;
}

#toons #toon4_10 {
    position: absolute;
    left: 574px;
    top: 403px;
    width: 81px;
    height: auto;
    opacity: 0;
}

#toons #toon4_11 {
    position: absolute;
    left: 665px;
    top: 351px;
    width: 80px;
    height: auto;
    opacity: 0;
}

#toons #toon4_12 {
    position: absolute;
    left: 57px;
    top: 482px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_13 {
    position: absolute;
    left: 89px;
    top: 568px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_14 {
    position: absolute;
    left: 160px;
    top: 522px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_15 {
    position: absolute;
    left: 246px;
    top: 569px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_16 {
    position: absolute;
    left: 327px;
    top: 615px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_17 {
    position: absolute;
    left: 399px;
    top: 547px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_18 {
    position: absolute;
    left: 465px;
    top: 620px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_19 {
    position: absolute;
    left: 543px;
    top: 589px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_20 {
    position: absolute;
    left: 559px;
    top: 512px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_21 {
    position: absolute;
    left: 639px;
    top: 537px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_22 {
    position: absolute;
    left: 679px;
    top: 456px;
    width: 64px;
    height: auto;
    opacity: 0;
}

#toons #toon4_23 {
    position: absolute;
    left: 43px;
    top: 600px;
    width: 700px;
    height: auto;
    opacity: 0;
}

#toons #toon4_24 {
    position: absolute;
    left: 47px;
    top: 698px;
    width: 693px;
    height: auto;
    opacity: 0;
}

#toons #toon4_25 {
    position: absolute;
    left: 67px;
    top: 784px;
    width: 676px;
    height: auto;
    opacity: 0;
}

#toons #toon4_26 {
    position: absolute;
    left: 266px;
    top: 187px;
    width: 275px;
    height: auto;

    opacity: 0;
}

#toons #toon4_27 {
    position: absolute;
    left: 141px;
    top: 333px;
    width: 521px;
    height: auto;
    opacity: 0;
}

#toons #toon4_28 {
    position: absolute;
    left: 109px;
    top: 438px;
    width: 601px;
    height: auto;
    opacity: 0;
}

#toons #toon4_29 {
    position: absolute;
    left: 60px;
    top: 534px;
    width: 666px;
    height: auto;
    opacity: 0;
}

#toons #toon4_30 {
    position: absolute;
    left: 83px;
    top: 1007px;
    width: 649px;
    height: auto;
    opacity: 0;
}

#toons #toon4_31 {
    position: absolute;
    left: 51px;
    top: -80px;
    width: 702px;
    height: auto;
    opacity: 1;
}

#toons #toon4_32 {
    position: absolute;
    left: 51px;
    top: -80px;
    width: 702px;
    height: auto;
    opacity: 1;
}

#toons #toon6 {
    width: 100%;
    height: 1160px;
    margin-top: -551px;
    position: relative;
}

#toons #toon6_1 {
    position: absolute;
    left: 482px;
    top: 636px;
    width: 144px;
    height: auto;
    opacity: 0;
}

#toons #toon6_2 {
    position: absolute;
    left: 412px;
    top: 363px;
    width: 144px;
    height: auto;
    opacity: 0;
}

#toons #toon6_3 {
    position: absolute;
    left: 319px;
    top: 183px;
    width: 145px;
    height: auto;
    opacity: 0;
}

#toons #toon6_4 {
    position: absolute;
    left: 320px;
    top: 0px;
    width: 144px;
    height: auto;
    opacity: 0;
}

#toons #toon6_5 {
    position: absolute;
    left: 51px;
    top: 33px;
    width: 709px;
    height: auto;
}

#toons #toon6_6 {
    position: absolute;
    left: 312px;
    top: 599px;
    width: 172px;
    height: auto;
    opacity: 0;
}

#toons #toon6_6_desc {
    position: absolute;
    left: 308px;
    top: 715px;
    width: 172px;
    height: 32px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: 'kyobofont';
    font-size: 30px;
    letter-spacing: -0.6;
    text-align: center;
    color: #f27a2e;
}

#toons #toon6_7 {
    position: absolute;
    left: 548px;
    top: 357px;
    width: 172px;
    height: auto;
    opacity: 0;
}

#toons #toon6_7_desc {
    position: absolute;
    left: 559px;
    top: 446px;
    width: 160px;
    height: 32px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: 'kyobofont';
    font-size: 30px;
    letter-spacing: -0.6;
    text-align: center;
    color: #584935;
}

#toons #toon6_8 {
    position: absolute;
    left: 144px;
    top: 190px;
    width: 172px;
    height: auto;
    opacity: 0;
}

#toons #toon6_8_desc {
    position: absolute;
    left: 144px;
    top: 279px;
    width: 172px;
    height: 32px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: 'kyobofont';
    font-size: 30px;
    letter-spacing: -0.6;
    text-align: center;
    color: #584935;
}

#toons #toon6_9 {
    position: absolute;
    left: 455px;
    top: 6px;
    width: 172px;
    height: auto;
    opacity: 0;
}

#toons #toon6_9_desc {
    position: absolute;
    left: 467px;
    top: 95px;
    width: 160px;
    height: 32px;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: 'kyobofont';
    font-size: 30px;
    letter-spacing: -0.6;
    text-align: center;
    color: #584935;
}

#toons #toon6_10 {
    position: absolute;
    left: 494px;
    top: 489px;
    width: 46px;
    height: auto;
    opacity: 0;
}

#toons #toon6_11 {
    position: absolute;
    left: 414px;
    top: 312px;
    width: 36px;
    height: auto;
    opacity: 0;
}

#toons #toon6_12 {
    position: absolute;
    left: 386px;
    top: 136px;
    width: 9px;
    height: auto;
    opacity: 0;
}

#toons #toon6_13_desc {
    position: absolute;
    left: 0px;
    top: 834px;
    width: 100%;
    opacity: 0;

    font-family: 'kyobofont';
    font-size: 28px;
    text-align: center;
    color: #3a2929;
}

#toons #toon7 {
    width: 100%;
    height: 559px;
    margin-top: -20px;
    position: relative;
}

#toons #toon7_1 {
    position: absolute;
    left: 52px;
    top: 146px;
    width: 689px;
    height: auto;
}

#toons #toon7_2 {
    position: absolute;
    left: 51px;
    top: 0px;
    width: 307px;
    height: auto;
}

#toons #toon7_2_desc1 {
    position: absolute;
    left: 70px;
    top: 15px;
    font-family: 'kyobofont';
    font-size: 24px;
    text-align: center;
    color: #584935;
}

#toons #toon7_2_desc2 {
    position: absolute;
    left: 51px;
    top: 45px;
    width: 307px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: 'kyobofont';
    font-size: 46px;
    text-align: center;
    color: #584935;
}

#toons #toon7_3 {
    position: absolute;
    left: 423px;
    top: 0px;
    width: 307px;
    height: auto;
}

#toons #toon7_3_desc1 {
    position: absolute;
    left: 441px;
    top: 15px;
    font-family: 'kyobofont';
    font-size: 24px;
    text-align: center;
    color: #584935;
}

#toons #toon7_3_desc2 {
    position: absolute;
    left: 423px;
    top: 45px;
    width: 307px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-family: 'kyobofont';
    font-size: 46px;
    text-align: center;
    color: #584935;
}

#toons #toon8 {
    width: 100%;
    height: 550px;
    margin-top: 153px;
    position: relative;
}

#toons #toon8_1 {
    position: absolute;
    left: 0px;
    top: 148px;
    width: 762px;
    height: auto;
}

#toons_footer {
    width: 100%;
    height: 271px;
}

@media (max-width: 1000px) {
    .webtoon_container {
        width: 100%;
    }

    #webtoon_header {
        padding-top: 44px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        height: 284px;
        background-color: #ffa500;
    }

    #webtoon_header_copy {
        position: absolute;
        left: 0px;
        top: 77px;
        width: 100%;

        font-size: 16px;
        line-height: 1.25;
        text-align: center;
    }

    #webtoon_close_pc {
        display: none;
    }

    #webtoon_close_mobile {
        display: flex;
        cursor: pointer;
        position: fixed;
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        z-index: 10;
    }

    #webtoon_header_image {
        width: 375px;
        height: 340px;
        background-image: url('./resources/header_mobile.png');
    }

    #toons {
        margin-top: 83px;
        width: 375px;
    }

    /* pc 100% = 762 */
    #toons #toon1 {
        height: calc((375 / 762) * 661px);
    }

    #toons #toon1_1 {
        left: calc((375 / 762) * 81px);
        top: calc((375 / 762) * 82px);
        width: calc((375 / 762) * 535px);
    }

    #toons #toon1_2 {
        left: calc((375 / 762) * 365px);
        top: calc((375 / 762) * 246px);
        width: calc((375 / 762) * 368px);
    }

    #toons #toon1_3 {
        left: calc((375 / 762) * 108px);
        top: calc((375 / 762) * 0px);
        width: calc((375 / 762) * 185px);
    }

    #toons #toon1_4 {
        left: calc((375 / 762) * 246px);
        top: calc((375 / 762) * 232px);
        width: calc((375 / 762) * 237px);
    }

    #toons #toon1_4_desc {
        left: calc((375 / 762) * 246px);
        top: calc((375 / 762) * 232px);
        width: calc((375 / 762) * 237px);
        height: calc((375 / 762) * 210px);

        font-size: calc((375 / 762) * 38px);
    }

    #toons #toon1_5_desc {
        left: calc((375 / 762) * 0px);
        top: calc((375 / 762) * 634px);
        width: 100%;
        height: calc((375 / 762) * 27px);

        font-size: calc((375 / 762) * 28px);
    }

    #toons #toon2 {
        width: 100%;
        height: calc((375 / 762) * 949px);
        margin-top: calc((375 / 762) * 215px);
    }

    #toons #toon2_1 {
        left: calc((375 / 762) * 56px);
        top: calc((375 / 762) * 0px);
        width: calc((375 / 762) * 343px);
    }

    #toons #toon2_2 {
        left: calc((375 / 762) * 404px);
        top: calc((375 / 762) * 276px);
        width: calc((375 / 762) * 340px);
    }

    #toons #toon2_3 {
        left: calc((375 / 762) * 87px);
        top: calc((375 / 762) * 460px);
        width: calc((375 / 762) * 475px);
    }

    #toons #toon2_4 {
        left: calc((375 / 762) * 31px);
        top: calc((375 / 762) * 401px);
        width: calc((375 / 762) * 255px);
    }

    #toons #toon2_4_desc {
        left: calc((375 / 762) * 31px);
        top: calc((375 / 762) * 401px);
        width: calc((375 / 762) * 255px);
        height: calc((375 / 762) * 255px);

        font-size: calc((375 / 762) * 40px);
    }

    #toons #toon3 {
        width: 100%;
        height: calc((375 / 762) * 1023px);
        margin-top: calc((375 / 762) * 232px);
    }

    #toons #toon3_1 {
        left: calc((375 / 762) * 227px);
        top: calc((375 / 762) * 54px);
        width: calc((375 / 762) * 413px);
    }

    #toons #toon3_2 {
        left: calc((375 / 762) * 160px);
        top: calc((375 / 762) * 453px);
        width: calc((375 / 762) * 521px);
    }

    #toons #toon3_3 {
        left: calc((375 / 762) * 83px);
        top: calc((375 / 762) * 0px);
        width: calc((375 / 762) * 263px);
    }

    #toons #toon3_4 {
        left: calc((375 / 762) * 184px);
        top: calc((375 / 762) * 238px);
        width: calc((375 / 762) * 199px);
    }

    #toons #toon3_4_desc {
        left: calc((375 / 762) * 184px);
        top: calc((375 / 762) * 250px);
        width: calc((375 / 762) * 199px);
        height: calc((375 / 762) * 180px);

        font-size: calc((375 / 762) * 40px);
    }

    #toons #toon3_5 {
        left: calc((375 / 762) * 501px);
        top: calc((375 / 762) * 481px);
        width: calc((375 / 762) * 250px);
    }

    #toons #toon3_5_desc {
        left: calc((375 / 762) * 501px);
        top: calc((375 / 762) * 481px);
        width: calc((375 / 762) * 250px);
        height: calc((375 / 762) * 220px);

        font-size: calc((375 / 762) * 40px);
    }

    #toons #toon3_6_desc {
        left: calc((375 / 762) * 0px);
        top: calc((375 / 762) * 997px);
        width: 100%;
        height: calc((375 / 762) * 26px);

        font-size: calc((375 / 762) * 28px);
    }

    #toons #toon4 {
        width: 100%;
        height: calc((375 / 762) * 1803px);
        margin-top: calc((375 / 762) * 122px);
    }

    #toons #toon4_1 {
        left: calc((375 / 762) * 320px);
        top: calc((375 / 762) * 0px);
        width: calc((375 / 762) * 156px);
    }

    #toons #toon4_2 {
        left: calc((375 / 762) * 162px);
        top: calc((375 / 762) * 262px);
        width: calc((375 / 762) * 106px);
    }

    #toons #toon4_3 {
        left: calc((375 / 762) * 303px);
        top: calc((375 / 762) * 226px);
        width: calc((375 / 762) * 194px);
    }

    #toons #toon4_4 {
        left: calc((375 / 762) * 525px);
        top: calc((375 / 762) * 248px);
        width: calc((375 / 762) * 106px);
    }

    #toons #toon4_5 {
        left: calc((375 / 762) * 58px);
        top: calc((375 / 762) * 299px);
        width: calc((375 / 762) * 80px);
    }

    #toons #toon4_6 {
        left: calc((375 / 762) * 101px);
        top: calc((375 / 762) * 396px);
        width: calc((375 / 762) * 80px);
    }

    #toons #toon4_7 {
        left: calc((375 / 762) * 205px);
        top: calc((375 / 762) * 415px);
        width: calc((375 / 762) * 80px);
    }

    #toons #toon4_8 {
        left: calc((375 / 762) * 320px);
        top: calc((375 / 762) * 457px);
        width: calc((375 / 762) * 80px);
    }

    #toons #toon4_9 {
        left: calc((375 / 762) * 453px);
        top: calc((375 / 762) * 444px);
        width: calc((375 / 762) * 80px);
    }

    #toons #toon4_10 {
        left: calc((375 / 762) * 574px);
        top: calc((375 / 762) * 403px);
        width: calc((375 / 762) * 81px);
    }

    #toons #toon4_11 {
        left: calc((375 / 762) * 665px);
        top: calc((375 / 762) * 351px);
        width: calc((375 / 762) * 80px);
    }

    #toons #toon4_12 {
        left: calc((375 / 762) * 57px);
        top: calc((375 / 762) * 482px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_13 {
        left: calc((375 / 762) * 89px);
        top: calc((375 / 762) * 568px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_14 {
        left: calc((375 / 762) * 160px);
        top: calc((375 / 762) * 522px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_15 {
        left: calc((375 / 762) * 246px);
        top: calc((375 / 762) * 569px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_16 {
        left: calc((375 / 762) * 327px);
        top: calc((375 / 762) * 615px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_17 {
        left: calc((375 / 762) * 399px);
        top: calc((375 / 762) * 547px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_18 {
        left: calc((375 / 762) * 465px);
        top: calc((375 / 762) * 620px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_19 {
        left: calc((375 / 762) * 543px);
        top: calc((375 / 762) * 589px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_20 {
        left: calc((375 / 762) * 559px);
        top: calc((375 / 762) * 512px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_21 {
        left: calc((375 / 762) * 639px);
        top: calc((375 / 762) * 537px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_22 {
        left: calc((375 / 762) * 679px);
        top: calc((375 / 762) * 456px);
        width: calc((375 / 762) * 64px);
    }

    #toons #toon4_23 {
        left: calc((375 / 762) * 43px);
        top: calc((375 / 762) * 600px);
        width: calc((375 / 762) * 700px);
    }

    #toons #toon4_24 {
        left: calc((375 / 762) * 47px);
        top: calc((375 / 762) * 698px);
        width: calc((375 / 762) * 693px);
    }

    #toons #toon4_25 {
        left: calc((375 / 762) * 67px);
        top: calc((375 / 762) * 784px);
        width: calc((375 / 762) * 676px);
    }

    #toons #toon4_26 {
        left: calc((375 / 762) * 266px);
        top: calc((375 / 762) * 187px);
        width: calc((375 / 762) * 275px);
    }

    #toons #toon4_27 {
        left: calc((375 / 762) * 141px);
        top: calc((375 / 762) * 333px);
        width: calc((375 / 762) * 521px);
    }

    #toons #toon4_28 {
        left: calc((375 / 762) * 109px);
        top: calc((375 / 762) * 438px);
        width: calc((375 / 762) * 601px);
    }

    #toons #toon4_29 {
        left: calc((375 / 762) * 60px);
        top: calc((375 / 762) * 534px);
        width: calc((375 / 762) * 666px);
    }

    #toons #toon4_30 {
        left: calc((375 / 762) * 83px);
        top: calc((375 / 762) * 1007px);
        width: calc((375 / 762) * 649px);
    }

    #toons #toon4_31 {
        left: calc((375 / 762) * 51px);
        top: calc((375 / 762) * -80px);
        width: calc((375 / 762) * 702px);
    }

    #toons #toon4_32 {
        left: calc((375 / 762) * 51px);
        top: calc((375 / 762) * -80px);
        width: calc((375 / 762) * 702px);
    }

    #toons #toon6 {
        width: 100%;
        height: calc((375 / 762) * 1160px);
        margin-top: calc((375 / 762) * -551px);
    }

    #toons #toon6_1 {
        left: calc((375 / 762) * 482px);
        top: calc((375 / 762) * 636px);
        width: calc((375 / 762) * 144px);
    }

    #toons #toon6_2 {
        left: calc((375 / 762) * 412px);
        top: calc((375 / 762) * 363px);
        width: calc((375 / 762) * 144px);
    }

    #toons #toon6_3 {
        left: calc((375 / 762) * 319px);
        top: calc((375 / 762) * 183px);
        width: calc((375 / 762) * 145px);
    }

    #toons #toon6_4 {
        left: calc((375 / 762) * 320px);
        top: calc((375 / 762) * 0px);
        width: calc((375 / 762) * 144px);
    }

    #toons #toon6_5 {
        left: calc((375 / 762) * 51px);
        top: calc((375 / 762) * 33px);
        width: calc((375 / 762) * 709px);
    }

    #toons #toon6_6 {
        left: calc((375 / 762) * 312px);
        top: calc((375 / 762) * 599px);
        width: calc((375 / 762) * 172px);
    }

    #toons #toon6_6_desc {
        left: calc((375 / 762) * 308px);
        top: calc((375 / 762) * 715px);
        width: calc((375 / 762) * 172px);
        height: calc((375 / 762) * 32px);

        font-size: calc((375 / 762) * 30px);
    }

    #toons #toon6_7 {
        left: calc((375 / 762) * 548px);
        top: calc((375 / 762) * 357px);
        width: calc((375 / 762) * 172px);
    }

    #toons #toon6_7_desc {
        left: calc((375 / 762) * 559px);
        top: calc((375 / 762) * 446px);
        width: calc((375 / 762) * 160px);
        height: calc((375 / 762) * 32px);

        font-size: calc((375 / 762) * 30px);
    }

    #toons #toon6_8 {
        left: calc((375 / 762) * 144px);
        top: calc((375 / 762) * 190px);
        width: calc((375 / 762) * 172px);
    }

    #toons #toon6_8_desc {
        left: calc((375 / 762) * 144px);
        top: calc((375 / 762) * 279px);
        width: calc((375 / 762) * 172px);
        height: calc((375 / 762) * 32px);

        font-size: calc((375 / 762) * 30px);
    }

    #toons #toon6_9 {
        left: calc((375 / 762) * 455px);
        top: calc((375 / 762) * 6px);
        width: calc((375 / 762) * 172px);
    }

    #toons #toon6_9_desc {
        left: calc((375 / 762) * 467px);
        top: calc((375 / 762) * 95px);
        width: calc((375 / 762) * 160px);
        height: calc((375 / 762) * 32px);

        font-size: calc((375 / 762) * 30px);
    }

    #toons #toon6_10 {
        left: calc((375 / 762) * 494px);
        top: calc((375 / 762) * 489px);
        width: calc((375 / 762) * 46px);
    }

    #toons #toon6_11 {
        left: calc((375 / 762) * 414px);
        top: calc((375 / 762) * 312px);
        width: calc((375 / 762) * 36px);
    }

    #toons #toon6_12 {
        left: calc((375 / 762) * 386px);
        top: calc((375 / 762) * 136px);
        width: calc((375 / 762) * 9px);
    }

    #toons #toon6_13_desc {
        left: calc((375 / 762) * 0px);
        top: calc((375 / 762) * 834px);
        width: 100%;

        font-size: calc((375 / 762) * 28px);
    }

    #toons #toon7 {
        width: 100%;
        height: calc((375 / 762) * 559px);
        margin-top: calc((375 / 762) * -20px);
    }

    #toons #toon7_1 {
        left: calc((375 / 762) * 52px);
        top: calc((375 / 762) * 146px);
        width: calc((375 / 762) * 689px);
    }

    #toons #toon7_2 {
        left: calc((375 / 762) * 51px);
        top: calc((375 / 762) * 0px);
        width: calc((375 / 762) * 307px);
    }

    #toons #toon7_2_desc1 {
        left: calc((375 / 762) * 70px);
        top: calc((375 / 762) * 15px);

        font-size: calc((375 / 762) * 24px);
    }

    #toons #toon7_2_desc2 {
        left: calc((375 / 762) * 51px);
        top: calc((375 / 762) * 45px);
        width: calc((375 / 762) * 307px);
        height: calc((375 / 762) * 70px);

        font-size: calc((375 / 762) * 46px);
    }

    #toons #toon7_3 {
        left: calc((375 / 762) * 423px);
        top: calc((375 / 762) * 0px);
        width: calc((375 / 762) * 307px);
    }

    #toons #toon7_3_desc1 {
        left: calc((375 / 762) * 441px);
        top: calc((375 / 762) * 15px);

        font-size: calc((375 / 762) * 24px);
    }

    #toons #toon7_3_desc2 {
        left: calc((375 / 762) * 423px);
        top: calc((375 / 762) * 45px);
        width: calc((375 / 762) * 307px);
        height: calc((375 / 762) * 70px);

        font-size: calc((375 / 762) * 46px);
    }

    #toons #toon8 {
        width: 100%;
        height: calc((375 / 762) * 550px);
        margin-top: calc((375 / 762) * 108px);
    }

    #toons #toon8_1 {
        left: calc((375 / 762) * 0px);
        top: calc((375 / 762) * 148px);
        width: calc((375 / 762) * 762px);
    }

    #toons_footer {
        width: 100%;
        height: calc((375 / 762) * 271px);
    }
}
