#agreement_content1_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: center;
}

#agreement_content1_wrapper {
    padding-left: 60px;
    padding-top: 121px;
    width: 640px;
    padding-right: 280px;
    padding-bottom: 100px;
    /* margin-right: 360px; */
}

.agreement_content1_template01 {
    font-family: 'APPLE';
    font-weight: bold;
    font-size: 24px;
    line-height: 1.5;
    color: #2d2d2d;
    margin-bottom: 30px;
}

.agreement_content1_template02 {
    margin-top: 30px;
    font-family: 'APPLE';
    font-weight: bold;
    font-size: 18px;
    line-height: 1.57;
    color: #2d2d2d;
    margin-bottom: 30px;
}

.agreement_content1_template03 {
    list-style-type: none;
    font-family: 'APPLE';
    font-weight: bold;
    font-size: 14px;
    line-height: 1.57;
    color: #2d2d2d;
    margin-bottom: 6px;
}

.agreement_content1_template04 {
    list-style-type: decimal;
    /* margin-left: 10px; */
    font-family: 'APPLE';
    font-weight: normal;
    font-size: 14px;
    line-height: 1.57;
    color: #2d2d2d;
    margin-bottom: 6px;
}

.agreement_content1_template05 {
    list-style-type: lower-roman;
    /* margin-left: 20px; */
    font-family: 'APPLE';
    font-weight: normal;
    font-size: 14px;
    line-height: 1.57;
    color: #2d2d2d;
    margin-bottom: 6px;
}

@media (max-width: 1000px) {
    #agreement_content1_container {
        flex-direction: column;
    }

    #agreement_content1_wrapper {
        padding: 24px;
        width: calc(100% - 48px);
    }
}
