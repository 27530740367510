#maincard1_bg {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    /* align-items: center; */
}

#maincard1_bg_image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc((80 / 1080) * 100%);
    min-width: 1920px;
    width: 100%;
    height: calc((631 / 1080) * 100%);

    /* background-image: url('./resources/MainCard1/bg-01.png');
    background-repeat: no-repeat;
    background-size: cover; */

    transition-duration: 0.5s;
}

#maincard1_bg_image_mobile {
    display: none;
}

#maincard1_wrapper {
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 1920px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    flex: 1;
}

#maincard1_bg_footer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: calc((80 / 1080) * 100%);
    background-color: #ffffff;

    transition-duration: 0.5s;
}

.maincard1_icon {
    transition-duration: 0.5s;
}

#maincard1_icon1 {
    position: absolute;
    left: 483.9px;
    top: calc((89 / 1080) * 100vh);
    width: calc((158 / 1080) * 100vh);
    height: calc((216 / 1080) * 100vh);

    background-image: url('./resources/MainCard1/constel-01.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard1_icon2 {
    position: absolute;
    left: 743.2px;
    top: calc((89 / 1080) * 100vh);
    width: calc((183 / 1080) * 100vh);
    height: calc((181 / 1080) * 100vh);

    background-image: url('./resources/MainCard1/constel-03.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard1_icon3 {
    position: absolute;
    left: 1081px;
    top: calc((96 / 1080) * 100vh);
    width: calc((111 / 1080) * 100vh);
    height: calc((86 / 1080) * 100vh);

    background-image: url('./resources/MainCard1/planet-01.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard1_icon4 {
    position: absolute;
    left: 1233.5px;
    top: calc((140 / 1080) * 100vh);
    width: calc((161 / 1080) * 100vh);
    height: calc((157 / 1080) * 100vh);

    background-image: url('./resources/MainCard1/constel-04.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard1_icon5 {
    position: absolute;
    left: 1361px;
    top: calc((284 / 1080) * 100vh);
    width: calc((80 / 1080) * 100vh);
    height: calc((51 / 1080) * 100vh);

    background-image: url('./resources/MainCard1/shooting-01.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard1_icon6 {
    position: absolute;
    left: 1333px;
    top: calc((356 / 1080) * 100vh);
    width: calc((80 / 1080) * 100vh);
    height: calc((51 / 1080) * 100vh);

    background-image: url('./resources/MainCard1/shooting-01.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard1_icon7 {
    position: absolute;
    left: 470px;
    top: calc((479 / 1080) * 100vh);
    width: calc((200 / 1080) * 100vh);
    height: calc((103 / 1080) * 100vh);

    background-image: url('./resources/MainCard1/cloud-01.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard1_icon8 {
    position: absolute;
    left: 707px;
    top: calc((603 / 1080) * 100vh);
    width: calc((125 / 1080) * 100vh);
    height: calc((82 / 1080) * 100vh);

    background-image: url('./resources/MainCard1/cloud-02.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard1_icon9 {
    position: absolute;
    left: 1077px;
    top: calc((556 / 1080) * 100vh);
    width: calc((187 / 1080) * 100vh);
    height: calc((98 / 1080) * 100vh);

    background-image: url('./resources/MainCard1/cloud-03.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard1_icon10 {
    position: absolute;
    left: 1274px;
    top: calc((489 / 1080) * 100vh);
    width: calc((120 / 1080) * 100vh);
    height: calc((62 / 1080) * 100vh);

    background-image: url('./resources/MainCard1/cloud-04.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard1_circle1 {
    position: absolute;
    left: 159px;
    top: calc((71 / 1080) * 100vh);
    width: calc((127 / 1080) * 100vh);
    height: calc((127 / 1080) * 100vh);
    border-radius: calc((63.5 / 1080) * 100vh);
    background-color: #303136;
}

#maincard1_circle2 {
    position: absolute;
    left: 1677px;
    top: calc((45 / 1080) * 100vh);
    width: calc((79 / 1080) * 100vh);
    height: calc((79 / 1080) * 100vh);
    border-radius: calc((39.5 / 1080) * 100vh);
    background-color: #303136;
}

#maincard1_circle3 {
    position: absolute;
    left: 1828px;
    top: calc((295 / 1080) * 100vh);
    width: calc((140 / 1080) * 100vh);
    height: calc((140 / 1080) * 100vh);
    border-radius: calc((70 / 1080) * 100vh);
    background-color: #303136;
}

#maincard1_dot1 {
    position: absolute;
    left: 60px;
    top: 128px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}

#maincard1_dot2 {
    position: absolute;
    left: 152px;
    top: 343px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffa500;
}

#maincard1_dot3 {
    position: absolute;
    left: 203px;
    top: 444px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}

#maincard1_dot4 {
    position: absolute;
    left: 334px;
    top: 323px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}

#maincard1_dot5 {
    position: absolute;
    left: 362px;
    top: 138px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffa500;
}

#maincard1_dot6 {
    position: absolute;
    left: 570px;
    top: 428px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffa500;
}

#maincard1_dot7 {
    position: absolute;
    left: 613px;
    top: 250px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}

#maincard1_dot8 {
    position: absolute;
    left: 718px;
    top: 287px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffa500;
}

#maincard1_dot9 {
    position: absolute;
    left: 777px;
    top: 524px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}

#maincard1_dot10 {
    position: absolute;
    left: 868px;
    top: 257px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}

#maincard1_dot11 {
    position: absolute;
    left: 1007px;
    top: 195px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffa500;
}

#maincard1_dot12 {
    position: absolute;
    left: 1010px;
    top: 699px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffa500;
}

#maincard1_dot13 {
    position: absolute;
    left: 1070px;
    top: 299px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}

#maincard1_dot14 {
    position: absolute;
    left: 1163px;
    top: 429px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffa500;
}

#maincard1_dot15 {
    position: absolute;
    left: 1221px;
    top: 507px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}

#maincard1_dot16 {
    position: absolute;
    left: 1527px;
    top: 180px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffa500;
}

#maincard1_dot17 {
    position: absolute;
    left: 1606px;
    top: 448px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}

#maincard1_dot18 {
    position: absolute;
    left: 1670px;
    top: 292px;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background-color: #ffffff;
}

#maincard1_image {
    position: absolute;
    bottom: calc((31 / 1080) * 100%);
    left: 50%;

    transform: translateX(-50%);
    width: auto;
    height: calc((242 / 1080) * 100%);

    transition-duration: 0.5s;
}

#maincard1_copy {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc((315 / 1080) * 100%);

    font-family: 'APPLE';
    font-size: calc((40 / 1080) * 100vh);
    font-weight: bold;
    text-align: center;
    color: #ffffff;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard1_copy_mobile {
    display: none;
}

@media (max-width: 1000px) {
    #maincard1_bg_image {
        display: none;
    }

    #maincard1_bg_image_mobile {
        display: flex;

        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
        min-width: 0px;
        width: 100%;
        height: 170px;
        transition-duration: 0.5s;
    }

    #maincard1_wrapper {
        width: 100%;
    }

    #maincard1_bg_footer {
        height: 40px;
    }

    #maincard1_icon1 {
        position: absolute;
        left: calc(6 / 320 * 100vw);
        top: 65px;
        width: 63px;
        height: 87px;
    }

    #maincard1_icon2 {
        position: absolute;
        left: calc(123 / 320 * 100vw);
        top: 41px;
        width: 74px;
        height: 73px;
    }

    #maincard1_icon3 {
        position: absolute;
        left: calc(249 / 320 * 100vw);
        top: 53px;
        width: 45px;
        height: 36px;
    }

    #maincard1_icon4 {
        position: absolute;
        left: calc(249 / 320 * 100vw);
        top: 112px;
        width: 65px;
        height: 63px;
    }

    #maincard1_icon5 {
        display: none;
    }

    #maincard1_icon6 {
        display: none;
    }

    #maincard1_icon7 {
        position: absolute;
        left: calc(12 / 320 * 100vw);
        top: auto;
        bottom: 209px;
        width: 80px;
        height: 42px;
    }

    #maincard1_icon8 {
        position: absolute;
        left: calc(107 / 320 * 100vw);
        top: auto;
        bottom: 168px;
        width: 50px;
        height: 33px;
    }

    #maincard1_icon9 {
        position: absolute;
        left: calc(206 / 320 * 100vw);
        top: auto;
        bottom: 176px;
        width: 75px;
        height: 39px;
    }

    #maincard1_icon10 {
        position: absolute;
        left: calc(257 / 320 * 100vw);
        top: auto;
        bottom: 226px;
        width: 48px;
        height: 25px;
    }

    #maincard1_circle1 {
        display: none;
    }

    #maincard1_circle2 {
        display: none;
    }

    #maincard1_circle3 {
        display: none;
    }

    #maincard1_dot1 {
        position: absolute;
        left: calc(20 / 320 * 100vw);
        top: 88px;
        background-color: #ffffff;
    }

    #maincard1_dot2 {
        position: absolute;
        left: calc(42 / 320 * 100vw);
        top: 175px;
        background-color: #ffa500;
    }

    #maincard1_dot3 {
        position: absolute;
        left: calc(27 / 320 * 100vw);
        top: 243px;
        background-color: #ffffff;
    }

    #maincard1_dot4 {
        position: absolute;
        left: calc(233 / 320 * 100vw);
        top: 105px;
        background-color: #ffffff;
    }

    #maincard1_dot5 {
        position: absolute;
        left: calc(279 / 320 * 100vw);
        top: 233px;
        background-color: #ffffff;
    }

    #maincard1_dot6 {
        left: calc(237 / 320 * 100vw);
        top: 280px;
        background-color: #ffa500;
    }

    #maincard1_dot7 {
        left: calc(305 / 320 * 100vw);
        top: 71px;
        background-color: #ffa500;
    }

    #maincard1_dot8 {
        display: none;
    }

    #maincard1_dot9 {
        display: none;
    }

    #maincard1_dot10 {
        display: none;
    }

    #maincard1_dot11 {
        display: none;
    }

    #maincard1_dot12 {
        display: none;
    }

    #maincard1_dot13 {
        display: none;
    }

    #maincard1_dot14 {
        display: none;
    }

    #maincard1_dot15 {
        display: none;
    }

    #maincard1_dot16 {
        display: none;
    }

    #maincard1_dot17 {
        display: none;
    }

    #maincard1_dot18 {
        display: none;
    }

    #maincard1_image {
        bottom: 5px;
        width: 159px;
        height: 134px;
    }

    #maincard1_copy {
        display: none;
    }

    #maincard1_copy_mobile {
        display: flex;

        position: absolute;
        width: 100%;
        justify-content: center;
        bottom: calc(50% + 60px);

        font-family: 'APPLE';
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;

        opacity: 0;
        transition-duration: 0.5s;
    }
}
