.agreement_container {
    margin-top: var(--nav-height-pc);
    padding-left: 100px;
    width: 120px;

    display: flex;
    flex-direction: row;
    background-color: #ffffff;
}
#agreement_menu {
    display: flex;
    flex-direction: column;
    width: 259px;
    height: 100%;
}

.agreement_title {
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: 'APPLE';
    font-size: 16px;
    font-weight: bold;
    color: #2d2d2d;
}

.agreement_leftmenu {
    margin-top: 19px;
    height: 20px;
    font-family: 'APPLE';
    font-size: 14px;
    font-weight: normal;
    color: #777777;
    text-decoration: none;
}

.agreement_leftmenu:hover {
    font-weight: bold;
    color: #ffa500;
    text-decoration: underline;
}

.agreement_leftmenu_selected {
    margin-top: 19px;
    height: 20px;
    font-family: 'APPLE';
    font-size: 14px;
    font-weight: bold;
    color: #ffa500;
    text-decoration: underline;
    /* overflow-y: scroll; */
}

#agreement_contents {
    flex: 1;
    margin-right: 320px;
}

#agreement_menu_dummy {
    margin-top: 60px;
}

#agreement_separator {
    width: 1px;
    background-color: #e6e6e6;
}

@media (max-width: 1000px) {
    .agreement_container {
        margin-top: var(--nav-height-mobile);
        padding-left: 0px;
        width: 100%;
        min-width: 1px;
        height: 94px;
        flex-direction: column;

        position: relative;
    }

    .agreement_title {
        position: absolute;
        top: 19px;
        left: 24px;

        margin-bottom: 0px;
    }

    #agreement_bg {
        flex-direction: column;
        width: 100%;
    }

    #agreement_menu {
        flex-direction: row;
        width: 100%;
    }

    .agreement_leftmenu {
        margin-left: 40px;
        margin-top: 60px;
        height: 20px;
        font-family: 'APPLE';
        font-size: 20px;
        font-weight: bold;
        color: #777777;
        text-decoration: none;
    }

    .agreement_leftmenu_selected {
        margin-left: 40px;
        margin-top: 60px;
        height: 20px;
        font-family: 'APPLE';
        font-size: 20px;
        font-weight: bold;
        color: #ffa500;
        text-decoration: underline;
        /* overflow-y: scroll; */
    }

    #agreement_contents {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 24px;
    }

    #agreement_menu_dummy {
        margin-top: 0px;
        margin-left: -16px;
    }

    #agreement_separator {
        width: auto;
        height: 1px;
    }
}
