:root {
    --nav-height-pc: 60px;
    --nav-height-mobile: 44px;
}

@font-face {
    font-family: 'APPLE';
    src: url('../resources/fonts/AppleSDGothicNeoR.woff') format('woff');
    font-weight: normal;
}
@font-face {
    font-family: 'APPLE';
    src: url('../resources/fonts/AppleSDGothicNeoB.woff') format('woff');
    font-weight: bold;
}

@import 'mixins';
@import 'variables';

.navigation {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;

    height: $nav_height_pc;
    z-index: 20000;
    background-color: #ffffff;

    display: flex;
    flex-direction: column;

    @media (max-width: $mobile_width) {
        height: $nav_height_mobile;
    }

    &.white {
        background-color: white;
    }

    &.transparent {
        background-color: transparent;
    }
}

.nav_inner {
    position: relative;
    display: flex;
    width: $mobile_width;
    align-self: center;
    flex: 1;

    @media (max-width: $mobile_width) {
        width: 100%;
    }

    &.logo {
        margin-top: 19px;
        margin-left: 0px;
        width: 84px;
        height: 22px;
        margin-right: 60px;

        background-repeat: no-repeat;
        background-size: contain;

        @media (max-width: $mobile_width) {
            margin-top: 11px;
            margin-left: 24px;
        }

        &.yellow {
            background-image: url(./resources/logo-y.png);
        }

        &.white {
            background-image: url(./resources/logo-w.png);
        }
    }
}

#nav_buttons_pc {
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: $mobile_width) {
        display: none;
    }
}

#nav_buttons_mobile {
    display: none;

    @media (max-width: $mobile_width) {
        display: flex;
        flex-direction: row;
    }
}

.nav_point {
    @include buz_font(16px, #ffa500);

    display: flex;
    margin-top: 22px;
    margin-right: 10px;

    font-weight: bold;

    @media (max-width: $mobile_width) {
        margin-top: 0px;
        margin-right: 0px;
        position: absolute;

        top: 0px;
        height: $nav_height_mobile;
        right: 68px;

        justify-content: center;
        align-items: center;

        font-weight: normal;
    }

    &:small {
        font-size: 12px;
    }

    &.highlight {
        color: #ffa500;
    }

    &.white {
        color: white;
    }
}

.nav_menu_icon {
    display: none;

    @media (max-width: $mobile_width) {
        display: flex;
        top: 10px;
        right: 20px;
        position: absolute;
        width: 24px;
        height: 24px;

        background-size: contain;
        background-repeat: no-repeat;

        &.black {
            background-image: url(./resources/menu.png);
        }

        &.white {
            background-image: url(./resources/menu-w.png);
        }
    }
}

.nav_button_wrapper {
    width: auto;
    height: 100%;
    margin-right: 40px;
    text-decoration: none;

    .nav_menu_button {
        position: relative;
        display: flex;
        flex-direction: column;
        width: auto;
        height: 100%;
    }

    .nav_menu_button_text {
        @include buz_font(14px, #a0a0a0);

        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: auto;
        text-decoration: none;

        &.theme0 {
            color: #a8a8a8;
            &.selected {
                color: #ffa500;
            }
        }

        &.theme1 {
            color: white;
            &.selected {
                color: white;
            }
        }

        &.theme2 {
            color: #a8a8a8;
            &.selected {
                color: #ffa500;
            }
        }

        &.theme_else {
            color: white;
            &.selected {
                color: white;
            }
        }
    }

    .nav_underbar {
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0px;
        background-color: white;

        &.highlight {
            background-color: #ffa500;
        }
    }
}

#nav_language {
    top: 15px;
    right: 0px;
    width: 80px;
    height: 30px;
    border-radius: 15px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    position: absolute;

    &:hover {
        height: 50px;
    }
}

@mixin nav_language_label() {
    @include buz_font(13px, #a0a0a0);

    cursor: pointer;
    position: absolute;
    left: 0px;
    padding-left: 18px;

    width: 80px;
    height: 13px;

    text-decoration: none;

    &:hover {
        color: #ffa500;
    }
}

#nav_language_label1 {
    @include nav_language_label();
    top: 7px;
}

#nav_language_label2 {
    @include nav_language_label();
    top: 28px;
}

.nav_language_arrow {
    position: absolute;
    margin-left: 14px;
    width: 14px;
    height: 14px;
    right: 11px;
    top: 8.5px;
}

#nav_login_view {
    display: flex;
    position: absolute;
    width: 140px;
    top: calc(var(--nav-height-pc) * -1);
    margin-top: $nav_height_pc;
    z-index: 20100;
    right: 125px;

    @media (max-width: $mobile_width) {
        display: none;
    }
}

#login_inner_view {
    width: calc(100% - 40px);
    padding: 20px;
    border-radius: 5px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    margin-top: 54px;
    display: flex;
    flex-direction: column;
}

#nav_login_id {
    @include buz_font(14px, #2d2d2d);

    text-overflow: ellipsis;
    overflow: hidden;

    white-space: nowrap;
}

.nav_login_info_popup_button {
    @include buz_font(12px, #2d2d2d);

    cursor: pointer;

    height: 24px;
    border-radius: 5px;
    border: solid 1px #2d2d2d;

    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.cls_nav_menu_wrapper {
    display: none;

    @media (max-width: $mobile_width) {
        display: block;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 200000;
        background-color: rgba(0, 0, 0, 0.5);
        transition-duration: 0.3s;
        transition-delay: 0s;
        visibility: hidden;
        opacity: 0;

        &.visible {
            visibility: visible;
            opacity: 1;
        }

        .nav_menu_view {
            position: absolute;
            right: 0px;
            transform: translateX(100%);

            top: 0px;
            width: 240px;
            height: 100%;
            background-color: white;
            transition-duration: 0.3s;
            transition-delay: 0s;

            display: flex;
            flex-direction: column;

            &.visible {
                transform: translateX(0%);
            }
        }

        #nav_menu_close {
            margin-left: 196px;
            margin-top: 10px;
            width: 24px;
            height: 24px;
            background-image: url('./resources/m-x.png');
            background-repeat: no-repeat;
            background-size: contain;
        }

        #nav_menu_info_label {
            @include buz_font(14px, #2d2d2d);

            margin-top: 23px;
            margin-left: 30px;
            margin-right: 30px;

            line-height: 1.57;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        #nav_menu_info_buttons {
            margin-top: 30px;
            margin-left: 30px;
            margin-right: 30px;
            display: flex;
            flex-direction: row;
        }

        .nav_menu_info_button {
            @include buz_font(12px, #2d2d2d);

            cursor: pointer;

            flex: 1;
            height: 30px;
            border-radius: 5px;
            border: solid 1px #e6e6e6;
            text-align: center;

            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
        }

        .nav_menu_item {
            @include buz_font(18px, #2d2d2d);

            padding-left: 30px;
            height: 50px;

            display: flex;
            align-items: center;
            text-decoration: none;
        }

        #nav_menu_language_box {
            width: 100%;
            height: 77px;
            display: flex;
            flex-direction: column;
        }

        .nav_menu_language_text {
            @include buz_font(14px, #2d2d2d);

            cursor: pointer;
            text-decoration: none;
        }
    }
}

.nav_bottom_line {
    height: 1px;
    background-color: #e6e6e6;
    display: none;

    &.show {
        display: flex;

        @media (max-width: $mobile_width) {
            display: none;
        }
    }

    @media (max-width: $mobile_width) {
        display: none;
    }
}

#nav_login_icon {
    margin-top: 15px;
    width: 30px;
    height: 30px;
}

.cls_nav_login_label {
    @include buz_font(14px, #777777);

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;

    text-decoration: none;

    &.white {
        color: white;
    }
}
