.buzpointintro_container {
    margin-top: var(--nav-height-pc);
    width: 100%;
    min-width: 1000px;
    height: calc(100vh - var(--nav-height-pc));
    display: flex;
    flex-direction: column;
    background-color: white;
    position: relative;

    align-items: center;

    overflow: hidden;
}

.buzpointintro_wrapper {
    width: 1000px;
    height: 100%;

    position: relative;

    display: flex;
    flex-direction: column;
}

#buzpointintro_text1 {
    margin-top: calc(100vh * (320 / 1080));
    font-family: 'APPLE';
    font-size: 32px;
    font-weight: bold;
    color: #000000;
}

#buzpointintro_text1 b {
    font-weight: bold;
    color: #ffa500;
}

#buzpointintro_text2 {
    margin-top: 10px;
    font-family: 'APPLE';
    font-size: 20px;
    color: #000000;
}

#buzpointintro_text2 b {
    font-weight: normal;
    color: #ffa500;
}

#buzpointintro_text2 small {
    color: #a0a0a0;
}

#buzpointintro_wrapper {
    display: flex;
    flex-direction: row;
}

#buzpointintro_button {
    cursor: pointer;
    margin-top: 30px;
    width: auto;
    height: 48px;
    border-radius: 24px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #ffa500;

    font-family: 'APPLE';
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;

    display: flex;
    justify-content: center;
    align-items: center;
}

#buzpointintro_image {
    position: absolute;

    right: 0px;
    top: calc(100vh * (202 / 1080));

    width: 583px;
    height: 531px;

    background-image: url('./resources/img-web-buzpoint-01.png');
    background-repeat: no-repeat;
    background-size: contain;
}

@media (max-width: 1000px) {
    .buzpointintro_container {
        position: absolute;
        margin-top: var(--nav-height-mobile);
        flex: 1;
        min-width: 0px;
        height: calc(100% - var(--nav-height-mobile));
        overflow: auto;
        overflow-x: hidden;
    }

    .buzpointintro_wrapper {
        width: calc(100% - 48px);
        margin-left: 24px;
        margin-right: 24px;
        min-height: 590px;

        position: relative;
    }

    #buzpointintro_text1 {
        margin-top: 349px;
        font-size: 20px;
    }

    #buzpointintro_text2 {
        margin-top: 10px;
        font-size: 14px;
    }

    #buzpointintro_wrapper {
        display: flex;
        flex-direction: column;
    }

    #buzpointintro_button {
        position: fixed;
        width: calc(100% - 48px);
        padding-left: 0px;
        padding-right: 0px;
        left: 24px;
        bottom: 20px;
        height: 48px;

        font-size: 16px;
    }

    #buzpointintro_image {
        position: absolute;

        left: 50%;
        transform: translateX(-50%);
        right: auto;
        top: 24px;

        width: 272px;
        height: 305px;

        background-image: url('./resources/img-m-buzpoint-01.png');
    }
}
