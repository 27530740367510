#maincard5_bg {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    /* align-items: center; */
}

#maincard5_wrapper {
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 1920px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    flex: 1;
}

#maincard5_icon1 {
    position: absolute;
    left: 648px;
    top: calc((236 / 1080) * 100vh);
    width: calc((12 / 1080) * 100vh);
    height: calc((12 / 1080) * 100vh);
    border-radius: calc((6 / 1080) * 100vh);

    background-color: #ffd327;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard5_icon2 {
    position: absolute;
    left: 584px;
    top: calc((596 / 1080) * 100vh);
    width: calc((12 / 1080) * 100vh);
    height: calc((12 / 1080) * 100vh);
    border-radius: calc((6 / 1080) * 100vh);

    background-color: #ffd327;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard5_icon3 {
    position: absolute;
    left: 832px;
    top: calc((305 / 1080) * 100vh);
    width: calc((12 / 1080) * 100vh);
    height: calc((12 / 1080) * 100vh);
    border-radius: calc((6 / 1080) * 100vh);

    background-color: #ffd327;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard5_icon4 {
    position: absolute;
    left: 856px;
    top: calc((653 / 1080) * 100vh);
    width: calc((12 / 1080) * 100vh);
    height: calc((12 / 1080) * 100vh);
    border-radius: calc((6 / 1080) * 100vh);

    background-color: #ffd327;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard5_icon5 {
    position: absolute;
    left: 1096px;
    top: calc((238 / 1080) * 100vh);
    width: calc((20 / 1080) * 100vh);
    height: calc((20 / 1080) * 100vh);
    border-radius: calc((10 / 1080) * 100vh);

    background-color: #ffd327;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard5_icon6 {
    position: absolute;
    left: 1035px;
    top: calc((720 / 1080) * 100vh);
    width: calc((28 / 1080) * 100vh);
    height: calc((28 / 1080) * 100vh);
    border-radius: calc((14 / 1080) * 100vh);

    background-color: #ffd327;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard5_icon7 {
    position: absolute;
    left: 1238px;
    top: calc((476 / 1080) * 100vh);
    width: calc((20 / 1080) * 100vh);
    height: calc((20 / 1080) * 100vh);
    border-radius: calc((10 / 1080) * 100vh);

    background-color: #ffd327;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard5_icon8 {
    position: absolute;
    left: 1315px;
    top: calc((641 / 1080) * 100vh);
    width: calc((12 / 1080) * 100vh);
    height: calc((12 / 1080) * 100vh);
    border-radius: calc((6 / 1080) * 100vh);

    background-color: #ffd327;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard5_coin1 {
    position: absolute;
    left: 615.8px;
    top: calc((738.2 / 1080) * 100vh);
    width: calc((125 / 1080) * 100vh);
    height: calc((126 / 1080) * 100vh);

    background-image: url('./resources/MainCard5/point-coin-01.png');
    background-repeat: no-repeat;
    background-size: contain;

    transition-duration: 0.5s;
}

#maincard5_coin2 {
    position: absolute;
    left: 1158.6px;
    top: calc((737.2 / 1080) * 100vh);
    width: calc((151 / 1080) * 100vh);
    height: calc((157 / 1080) * 100vh);

    background-image: url('./resources/MainCard5/point-coin-02.png');
    background-repeat: no-repeat;
    background-size: contain;

    transition-duration: 0.5s;
}

#maincard5_coin3 {
    position: absolute;
    left: 678px;
    top: calc((496 / 1080) * 100vh);
    width: calc((108 / 1080) * 100vh);
    height: calc((107 / 1080) * 100vh);

    background-image: url('./resources/MainCard5/point-coin-03.png');
    background-repeat: no-repeat;
    background-size: contain;

    transition-duration: 0.5s;
}

#maincard5_coin4 {
    position: absolute;
    left: 595.4px;
    top: calc((254.4 / 1080) * 100vh);
    width: calc((153 / 1080) * 100vh);
    height: calc((155 / 1080) * 100vh);

    background-image: url('./resources/MainCard5/point-coin-04.png');
    background-repeat: no-repeat;
    background-size: contain;

    transition-duration: 0.5s;
}

#maincard5_coin5 {
    position: absolute;
    left: 1202px;
    top: calc((258 / 1080) * 100vh);
    width: calc((73 / 1080) * 100vh);
    height: calc((74 / 1080) * 100vh);

    background-image: url('./resources/MainCard5/point-coin-05.png');
    background-repeat: no-repeat;
    background-size: contain;

    transition-duration: 0.5s;
}

#maincard5_coin6 {
    position: absolute;
    left: 1128px;
    top: calc((591 / 1080) * 100vh);
    width: calc((62 / 1080) * 100vh);
    height: calc((62 / 1080) * 100vh);

    background-image: url('./resources/MainCard5/point-coin-06.png');
    background-repeat: no-repeat;
    background-size: contain;

    transition-duration: 0.5s;
}

#maincard5_copy {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc((372 / 1080) * 100vh);

    font-family: 'APPLE';
    font-size: calc((40 / 1080) * 100vh);
    font-weight: bold;
    text-align: center;
    color: #2d2d2d;
    opacity: 0;

    transition-duration: 0.5s;
}

#maincard5_copy b {
    color: #ffa500;
}

#maincard5_copy_mobile {
    display: none;
}

@keyframes shake {
    0% {
        transform: translateY(0px);
    }
    10% {
        transform: translateY(10px);
    }
    /* 20% {
        transform: translateY(0px);
    } */
    30% {
        transform: translateY(-10px);
    }
    /* 40% {
        transform: translateY(0px);
    } */
    50% {
        transform: translateY(10px);
    }
    /* 60% {
        transform: translateY(0px);
    } */
    70% {
        transform: translateY(-10px);
    }
    /* 80% {
        transform: translateY(0);
    } */
    90% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0px);
    }
}

.maincard5_shake1 {
    animation: shake 12s;
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
}

.maincard5_shake2 {
    animation: shake 9s;
    animation-delay: 0.3s;
    animation-iteration-count: infinite;
}

.maincard5_shake3 {
    animation: shake 8s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
}

.maincard5_shake4 {
    animation: shake 7s;
    animation-delay: 0.1s;
    animation-iteration-count: infinite;
}

.maincard5_shake5 {
    animation: shake 10s;
    animation-delay: 0.1s;
    animation-iteration-count: infinite;
}

.maincard5_shake6 {
    animation: shake 10s;
    animation-delay: 0.4s;
    animation-iteration-count: infinite;
}

@media (max-width: 1000px) {
    #maincard5_wrapper {
        width: 100%;
    }

    #maincard5_icon1 {
        left: calc(112 / 320 * 100%);
        top: calc(132 / 551 * 100%);
        width: 16px;
        height: 16px;
        border-radius: 8px;
    }

    #maincard5_icon2 {
        left: calc(81 / 320 * 100%);
        top: calc(304 / 551 * 100%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
    }

    #maincard5_icon3 {
        left: calc(128 / 320 * 100%);
        top: calc(385 / 551 * 100%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
    }

    #maincard5_icon4 {
        left: calc(85 / 320 * 100%);
        top: calc(458 / 551 * 100%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
    }

    #maincard5_icon5 {
        left: calc(217 / 320 * 100%);
        top: calc(96 / 551 * 100%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
    }

    #maincard5_icon6 {
        left: calc(232 / 320 * 100%);
        top: calc(228 / 551 * 100%);
        width: 8px;
        height: 8px;
        border-radius: 4px;
    }

    #maincard5_icon7 {
        left: calc(209 / 320 * 100%);
        top: calc(370 / 551 * 100%);
        width: 16px;
        height: 16px;
        border-radius: 8px;
    }

    #maincard5_icon8 {
        display: none;
    }

    #maincard5_coin1 {
        width: 75px;
        height: 76px;
    }

    #maincard5_coin2 {
        width: 91px;
        height: 95px;
    }

    #maincard5_coin3 {
        width: 65px;
        height: 66px;
    }

    #maincard5_coin4 {
        width: 92px;
        height: 93px;
    }

    #maincard5_coin5 {
        width: 44px;
        height: 45px;
    }

    #maincard5_coin6 {
        width: 38px;
        height: 38px;
    }

    #maincard5_copy {
        display: none;
    }

    #maincard5_copy_mobile {
        display: flex;

        position: absolute;
        width: 100%;
        justify-content: center;
        bottom: calc(50% + 60px);

        font-family: 'APPLE';
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #2d2d2d;

        opacity: 0;
        transition-duration: 0.5s;
    }

    #maincard5_copy_mobile b {
        color: #ffa500;
    }
}
