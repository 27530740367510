#maincard3_bg {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    /* align-items: center; */
}

#maincard3_wrapper {
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 1920px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    flex: 1;
}

#maincard3_icon1 {
    position: absolute;
    left: 839px;
    top: calc((698.6 / 1080) * 100vh);
    width: calc((53 / 1080) * 100vh);
    height: calc((53 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-01.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon2 {
    position: absolute;
    left: 1013px;
    top: calc((683.6 / 1080) * 100vh);
    width: calc((53 / 1080) * 100vh);
    height: calc((53 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-02.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon3 {
    position: absolute;
    left: 1291px;
    top: calc((745.6 / 1080) * 100vh);
    width: calc((39 / 1080) * 100vh);
    height: calc((25 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-03.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon4 {
    position: absolute;
    left: 587px;
    top: calc((336.6 / 1080) * 100vh);
    width: calc((62 / 1080) * 100vh);
    height: calc((95 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-04.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon5 {
    position: absolute;
    left: 694px;
    top: calc((615.6 / 1080) * 100vh);
    width: calc((62 / 1080) * 100vh);
    height: calc((80 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-05.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon6 {
    position: absolute;
    left: 1268px;
    top: calc((526.6 / 1080) * 100vh);
    width: calc((54 / 1080) * 100vh);
    height: calc((55 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-06.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon7 {
    position: absolute;
    left: 772px;
    top: calc((479.6 / 1080) * 100vh);
    width: calc((44 / 1080) * 100vh);
    height: calc((44 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-07.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon8 {
    position: absolute;
    left: 1101px;
    top: calc((483.6 / 1080) * 100vh);
    width: calc((80 / 1080) * 100vh);
    height: calc((80 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-08.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon9 {
    position: absolute;
    left: 794px;
    top: calc((255.6 / 1080) * 100vh);
    width: calc((41 / 1080) * 100vh);
    height: calc((41 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-09.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon10 {
    position: absolute;
    left: 716px;
    top: calc((783.6 / 1080) * 100vh);
    width: calc((25 / 1080) * 100vh);
    height: calc((25 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-10.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon11 {
    position: absolute;
    left: 853px;
    top: calc((342.6 / 1080) * 100vh);
    width: calc((25 / 1080) * 100vh);
    height: calc((25 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-11.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon12 {
    position: absolute;
    left: 586px;
    top: calc((483.6 / 1080) * 100vh);
    width: calc((107 / 1080) * 100vh);
    height: calc((101 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-12.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon13 {
    position: absolute;
    left: 1133px;
    top: calc((236.6 / 1080) * 100vh);
    width: calc((95 / 1080) * 100vh);
    height: calc((109 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-13.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon14 {
    position: absolute;
    left: 1257px;
    top: calc((606.6 / 1080) * 100vh);
    width: calc((107 / 1080) * 100vh);
    height: calc((101 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-14.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon15 {
    position: absolute;
    left: 1171px;
    top: calc((701.6 / 1080) * 100vh);
    width: calc((40 / 1080) * 100vh);
    height: calc((26 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-15.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon16 {
    position: absolute;
    left: 1045px;
    top: calc((283.6 / 1080) * 100vh);
    width: calc((41 / 1080) * 100vh);
    height: calc((28 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-16.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon17 {
    position: absolute;
    left: 1157px;
    top: calc((614.6 / 1080) * 100vh);
    width: calc((41 / 1080) * 100vh);
    height: calc((28 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-17.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon18 {
    position: absolute;
    left: 681px;
    top: calc((313.6 / 1080) * 100vh);
    width: calc((35 / 1080) * 100vh);
    height: calc((36 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-18.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon19 {
    position: absolute;
    left: 828px;
    top: calc((595.6 / 1080) * 100vh);
    width: calc((32 / 1080) * 100vh);
    height: calc((32 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-19.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon20 {
    position: absolute;
    left: 568px;
    top: calc((682.6 / 1080) * 100vh);
    width: calc((38 / 1080) * 100vh);
    height: calc((38 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-20.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon21 {
    position: absolute;
    left: 1112px;
    top: calc((757.6 / 1080) * 100vh);
    width: calc((38 / 1080) * 100vh);
    height: calc((38 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-21.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon22 {
    position: absolute;
    left: 1253px;
    top: calc((336.6 / 1080) * 100vh);
    width: calc((38 / 1080) * 100vh);
    height: calc((38 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-22.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_icon23 {
    position: absolute;
    left: 1253px;
    top: calc((469.6 / 1080) * 100vh);
    width: calc((20 / 1080) * 100vh);
    height: calc((20 / 1080) * 100vh);

    background-image: url('./resources/MainCard3/friends-23.png');
    background-repeat: no-repeat;
    background-size: contain;
}

#maincard3_bg1 {
    display: none;
}

#maincard3_bg2 {
    display: none;
}

#maincard3_bg3 {
    display: none;
}

.maincard3_icon {
    transition-duration: 0.5s;
}

.maincard3_bg {
    opacity: 0;
    transition-duration: 0.5s;
}

#maincard3_copy {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc((372 / 1080) * 100vh);

    font-family: 'APPLE';
    font-size: calc((40 / 1080) * 100vh);
    font-weight: bold;
    text-align: center;
    color: white;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard3_copy b {
    color: #2d2d2d;
}

#maincard3_copy_mobile {
    display: none;
}

@media (max-width: 1000px) {
    #maincard3_wrapper {
        position: relative;
        width: 100%;
    }

    #maincard3_icon1 {
        width: 21px;
        height: 21px;
    }

    #maincard3_icon2 {
        width: 21px;
        height: 21px;
    }

    #maincard3_icon3 {
        width: 16px;
        height: 10px;
    }

    #maincard3_icon4 {
        width: 25px;
        height: 38px;
    }

    #maincard3_icon5 {
        width: 25px;
        height: 32px;
    }

    #maincard3_icon6 {
        width: 22px;
        height: 22px;
    }

    #maincard3_icon7 {
        width: 18px;
        height: 18px;
    }

    #maincard3_icon8 {
        width: 32px;
        height: 32px;
    }

    #maincard3_icon9 {
        display: none;
    }

    #maincard3_icon10 {
        width: 10px;
        height: 10px;
    }

    #maincard3_icon11 {
        display: none;
    }

    #maincard3_icon12 {
        width: 43px;
        height: 41px;
    }

    #maincard3_icon13 {
        width: 38px;
        height: 44px;
    }

    #maincard3_icon14 {
        width: 43px;
        height: 41px;
    }

    #maincard3_icon15 {
        width: 16px;
        height: 11px;
    }

    #maincard3_icon16 {
        width: 17px;
        height: 12px;
    }

    #maincard3_icon17 {
        width: 17px;
        height: 12px;
    }

    #maincard3_icon18 {
        width: 14px;
        height: 15px;
    }

    #maincard3_icon19 {
        width: 13px;
        height: 13px;
    }

    #maincard3_icon20 {
        width: 15px;
        height: 15px;
    }

    #maincard3_icon21 {
        width: 15px;
        height: 15px;
    }

    #maincard3_icon22 {
        width: 15px;
        height: 15px;
    }

    #maincard3_icon23 {
        width: 8px;
        height: 8px;
    }

    #maincard3_bg1 {
        position: absolute;
        left: 1414px;
        top: calc((63.6 / 1080) * 100vh);
        width: calc((409 / 1080) * 100vh);
        height: calc((413 / 1080) * 100vh);

        background-image: url('./resources/MainCard3/friends-b-1.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    #maincard3_bg2 {
        position: absolute;
        left: 59px;
        top: calc((602.6 / 1080) * 100vh);
        width: calc((267 / 1080) * 100vh);
        height: calc((267 / 1080) * 100vh);

        background-image: url('./resources/MainCard3/friends-b-2.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    #maincard3_bg3 {
        position: absolute;
        left: 1617px;
        top: calc((809.6 / 1080) * 100vh);
        width: calc((216 / 1080) * 100vh);
        height: calc((216 / 1080) * 100vh);

        background-image: url('./resources/MainCard3/friends-b-3.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    .maincard3_icon {
        transition-duration: 0.5s;
    }

    .maincard3_bg {
        opacity: 0;
        transition-duration: 0.5s;
    }

    #maincard3_copy {
        display: none;
    }

    #maincard3_copy_mobile {
        display: flex;

        position: absolute;
        width: 100%;
        justify-content: center;
        bottom: calc(50% + 60px);

        font-family: 'APPLE';
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: white;

        opacity: 0;
        transition-duration: 0.5s;
    }

    #maincard3_copy_mobile b {
        color: #2d2d2d;
    }
}
