#maincard2_bg {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    /* align-items: center; */
}

#maincard2_wrapper {
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 1920px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    flex: 1;
}

#maincard2_icon1 {
    position: absolute;
    left: 763px;
    top: calc((118 / 1080) * 100vh);
    width: calc((74 / 1080) * 100vh);
    height: calc((82 / 1080) * 100vh);

    background-image: url('./resources/MainCard2/img-character-02.png');
    background-repeat: no-repeat;
    background-size: contain;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard2_icon2 {
    position: absolute;
    left: 1267px;
    top: calc((286 / 1080) * 100vh);
    width: calc((91 / 1080) * 100vh);
    height: calc((104 / 1080) * 100vh);

    background-image: url('./resources/MainCard2/img-character-03.png');
    background-repeat: no-repeat;
    background-size: contain;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard2_icon3 {
    position: absolute;
    left: 647px;
    top: calc((610 / 1080) * 100vh);
    width: calc((104 / 1080) * 100vh);
    height: calc((111 / 1080) * 100vh);

    background-image: url('./resources/MainCard2/img-character-04.png');
    background-repeat: no-repeat;
    background-size: contain;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard2_icon4 {
    position: absolute;
    left: 1102px;
    top: calc((890 / 1080) * 100vh);
    width: calc((93 / 1080) * 100vh);
    height: calc((91 / 1080) * 100vh);

    background-image: url('./resources/MainCard2/img-character-05.png');
    background-repeat: no-repeat;
    background-size: contain;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard2_icon5 {
    position: absolute;
    left: 539.9px;
    top: calc((80 / 1080) * 100vh);
    width: calc((94 / 1080) * 100vh);
    height: calc((443 / 1080) * 100vh);

    background-image: url('./resources/MainCard2/2-p-t-01.png');
    background-repeat: no-repeat;
    background-size: contain;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard2_icon5_dummy {
    position: absolute;
    left: 539.9px;
    top: calc((580 / 1080) * 100vh);
    width: calc((94 / 1080) * 100vh);
    height: calc((443 / 1080) * 100vh);

    background-color: white;
}

#maincard2_icon6 {
    position: absolute;
    left: 1287px;
    top: calc((479 / 1080) * 100vh);
    width: calc((94 / 1080) * 100vh);
    height: calc((521 / 1080) * 100vh);

    background-image: url('./resources/MainCard2/2-p-t-02.png');
    background-repeat: no-repeat;
    background-size: contain;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard2_icon6_dummy {
    position: absolute;
    left: 1287px;
    top: calc((0 / 1080) * 100vh);
    width: calc((94 / 1080) * 100vh);
    height: calc((342 / 1080) * 100vh);

    background-color: white;
}

#maincard2_dot1 {
    position: absolute;
    left: 696px;
    top: calc((246 / 1080) * 100vh);
    width: calc((13 / 1080) * 100vh);
    height: calc((13 / 1080) * 100vh);
    border-radius: calc((6.5 / 1080) * 100vh);
    background-color: #e6e6e6;
}

#maincard2_dot2 {
    position: absolute;
    left: 1043px;
    top: calc((301 / 1080) * 100vh);
    width: calc((12 / 1080) * 100vh);
    height: calc((12 / 1080) * 100vh);
    border-radius: calc((6 / 1080) * 100vh);
    background-color: #e6e6e6;
}

#maincard2_dot3 {
    position: absolute;
    left: 1182px;
    top: calc((637 / 1080) * 100vh);
    width: calc((13 / 1080) * 100vh);
    height: calc((13 / 1080) * 100vh);
    border-radius: calc((6.5 / 1080) * 100vh);
    background-color: #e6e6e6;
}

#maincard2_dot4 {
    position: absolute;
    left: 1334px;
    top: calc((165 / 1080) * 100vh);
    width: calc((13 / 1080) * 100vh);
    height: calc((13 / 1080) * 100vh);
    border-radius: calc((6.5 / 1080) * 100vh);
    background-color: #e6e6e6;
}

#maincard2_line1 {
    position: absolute;
    left: calc((856 / 1920) * 100%);
    top: calc((181 / 1080) * 100vh);
    width: calc((391.3 / 1920) * 100%);
    height: calc((135 / 1080) * 100vh);
}

#maincard2_line2 {
    position: absolute;
    left: calc((1043 / 1920) * 100%);
    top: calc((395.5 / 1080) * 100vh);
    width: calc((204 / 1920) * 100%);
    height: calc((102.6 / 1080) * 100vh);
}

#maincard2_line3 {
    position: absolute;
    left: calc((767.7 / 1920) * 100%);
    top: calc((583 / 1080) * 100vh);
    width: calc((106.3 / 1920) * 100%);
    height: calc((53.8 / 1080) * 100vh);
}

#maincard2_line4 {
    position: absolute;
    left: calc((764 / 1920) * 100%);
    top: calc((719 / 1080) * 100vh);
    width: calc((321 / 1920) * 100%);
    height: calc((185 / 1080) * 100vh);
}

#maincard2_line1_mobile {
    display: none;
}
#maincard2_line2_mobile {
    display: none;
}
#maincard2_line3_mobile {
    display: none;
}
#maincard2_line4_mobile {
    display: none;
}

.maincard2_others {
    opacity: 0;
    transition-duration: 0.5s;
}

#maincard2_copy {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc((372 / 1080) * 100vh);

    font-family: 'APPLE';
    font-size: calc((40 / 1080) * 100vh);
    font-weight: bold;
    text-align: center;
    color: #2d2d2d;

    opacity: 0;
    transition-duration: 0.5s;
}

#maincard2_copy b {
    color: #ffa500;
}

#maincard2_copy_mobile {
    display: none;
}

@media (max-width: 1000px) {
    #maincard2_wrapper {
        position: relative;
        width: 100%;
    }

    #maincard2_icon1 {
        position: absolute;
        left: calc(50vw - 150px);
        top: calc(50% - 215px);
        width: 71px;
        height: 79px;
    }

    #maincard2_icon2 {
        position: absolute;
        left: calc(50vw + 71px);
        top: calc(50% - 197px);
        width: 73px;
        height: 83px;
    }

    #maincard2_icon3 {
        position: absolute;
        left: calc(50vw - 136px);
        top: calc(50% + 51px);
        width: 83px;
        height: 89px;
    }

    #maincard2_icon4 {
        position: absolute;
        left: calc(50vw + 66px);
        top: calc(50% + 182px);
        width: 74px;
        height: 73px;
    }

    #maincard2_icon5 {
        display: none;
    }

    #maincard2_icon5_dummy {
        display: none;
    }

    #maincard2_icon6 {
        display: none;
    }

    #maincard2_icon6_dummy {
        display: none;
    }

    #maincard2_dot1 {
        position: absolute;
        left: calc(148 / 320 * 100vw);
        top: calc(94 / 551 * 100vh);
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: #e6e6e6;
    }

    #maincard2_dot2 {
        position: absolute;
        left: calc(252 / 320 * 100vw);
        top: calc(377 / 551 * 100vh);
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: #e6e6e6;
    }

    #maincard2_dot3 {
        position: absolute;
        left: calc(85 / 320 * 100vw);
        top: calc(471 / 551 * 100vh);
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: #e6e6e6;
    }

    #maincard2_dot4 {
        display: none;
    }

    #maincard2_line1 {
        display: none;
    }

    #maincard2_line2 {
        display: none;
    }

    #maincard2_line3 {
        display: none;
    }

    #maincard2_line4 {
        display: none;
    }

    #maincard2_line1_mobile {
        display: flex;
        position: absolute;
        left: calc(50vw - 69px);
        top: calc(50% - 167.5px);
        width: 134px;
        height: 13px;
    }

    #maincard2_line2_mobile {
        display: flex;
        position: absolute;
        left: calc(50vw + 36px);
        top: calc(50% - 114.5px);
        width: 63px;
        height: 80px;
    }

    #maincard2_line3_mobile {
        display: flex;
        position: absolute;
        left: calc(50vw - 59px);
        top: calc(50% + 35.5px);
        width: 26px;
        height: 29px;
    }

    #maincard2_line4_mobile {
        display: flex;
        position: absolute;
        left: calc(50vw - 53px);
        top: calc(50% + 129.5px);
        width: 118px;
        height: 72px;
    }

    .maincard2_others {
        opacity: 0;
        transition-duration: 0.5s;
    }

    #maincard2_copy {
        display: none;
    }

    #maincard2_copy_mobile {
        display: flex;

        position: absolute;
        width: 100%;
        justify-content: center;
        bottom: calc(50% + 60px);

        font-family: 'APPLE';
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #2d2d2d;

        opacity: 0;
        transition-duration: 0.5s;
    }

    #maincard2_copy_mobile b {
        color: #ffa500;
    }
}
