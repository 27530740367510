@font-face {
    font-family: 'kyobofont';
    src: url('../resources/fonts/kyobofont.woff') format('woff');
}

body {
    margin: 0px;
    width: 100vw;
    background-color: #ffffff;
}

.main_container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7ece1;

    overflow: hidden;
}

#main_card_wrapper {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: black;
    overflow: hidden;

    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.main_card {
    position: relative;
    /* min-width: 1920px; */

    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;

    transition-duration: 0.5s;
}

#main_card_dot_wrapper {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

#main_card_pager_wrapper {
    position: absolute;
    right: calc(50% - 456px);
    top: 50%;
    transform: translateX(50%) translateY(-50%);
    width: 19px;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#main_card_scrollguide {
    position: absolute;
    left: calc(50% - 456px);
    bottom: 100px;
    transform: translateX(-50%) translateY(0%);
    width: auto;
    height: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;

    transition-duration: 0.5s;
}

#main_card_scrollguide_text {
    margin-top: 15px;
    transform: rotate(-90deg);
    font-family: 'APPLE';
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #2d2d2d;
}

#main_card_scrollguide_icon {
    margin-top: 15px;
    width: 4px;
    height: 34px;
}

.main_card_pager_text {
    font-family: 'APPLE';
    font-size: 14px;
    text-align: center;
    color: #ffffff;
}

.main_card_pager_image {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 17px;
    height: 133px;
    transition-duration: 0.5s;

    background-repeat: no-repeat;
    background-size: contain;
}

.main_card_pager_button {
    cursor: pointer;
    position: absolute;

    left: 1px;
    width: 17px;
    height: 17px;
}

#main_card_pager_button1 {
    top: 33px;
}

#main_card_pager_button2 {
    top: 62px;
}

#main_card_pager_button3 {
    top: 91px;
}

#main_card_pager_button4 {
    top: 120px;
}

#main_card_pager_button5 {
    top: 149px;
}

#main_card_dot {
    position: absolute;
    left: calc(50% - (160 / 1080 * 100vh) / 2);
    top: calc(50% - (160 / 1080 * 100vh) / 2);

    width: calc(160 / 1080 * 100vh);
    height: calc(160 / 1080 * 100vh);

    border-radius: calc(80 / 1080 * 100vh);
    transition-duration: 0.5s;
    background-color: #ffa500;

    display: flex;
    justify-content: center;
    align-items: center;
}

#main_card_dot_text_wrapper {
    position: absolute;
    left: calc(50% - (160 / 1080 * 100vh) / 2);
    top: calc(50% - (160 / 1080 * 100vh) / 2);

    width: calc(160 / 1080 * 100vh);
    height: calc(160 / 1080 * 100vh);

    border-radius: calc(80 / 1080 * 100vh);

    transition-duration: 0.5s;
}

#main_card_dot_text {
    width: 100%;
    height: calc(58 / 1080 * 100vh);

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 15%;
    margin-left: 50%;
    transform: translateX(-50%);
    font-family: 'APPLE';
    font-size: calc(24 / 1080 * 100vh);
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: #ffffff;
}

#main_card_dot_hand_wrapper {
    cursor: pointer;
    position: absolute;
    left: 0px;
    top: 0px;

    left: calc(50% - (160 / 1080 * 100vh) / 2);
    top: calc(50% - (160 / 1080 * 100vh) / 2);
    width: calc(160 / 1080 * 100vh);
    height: calc(160 / 1080 * 100vh);
    border-radius: calc(80 / 1080 * 100vh);

    transition-duration: 0.5s;

    opacity: 0;
}

#main_card_dot_hand {
    position: relative;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 60%;

    width: calc(25 / 1080 * 100vh);
    height: calc(41 / 1080 * 100vh);
}

#main_card1 {
    background-color: #2c2d33;
}

#main_card2 {
    background-color: #ffffff;
}

#main_card3 {
    background-color: #ffa500;
}

#main_card4 {
    background-color: black;
}

#main_card5 {
    background-color: white;
}

#main_card6 {
    height: auto;
}

#main_delay_popup_bg {
    background-color: transparent;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 50000;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    align-items: center;
    justify-content: center;
}

.main_delay_popup_wrapper {
    position: fixed;
    /* width: calc(100% - 42px); */
    width: 100%;

    max-width: 318px;
    /* height: 180px; */
    border-radius: 9px;
    border: solid 1px rgba(45, 45, 45, 0.8);
    background-color: #fafafa;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: hidden;
}

#main_delay_popup_label {
    cursor: pointer;

    position: absolute;

    top: 363px;
    left: 50%;
    transform: translateX(-50%);

    width: 200px;
    height: 40px;
    border-radius: 20px;
    background-color: white;

    font-family: 'APPLE';
    font-size: 16px;
    font-weight: bold;
    color: #5533d4;

    display: flex;
    align-items: center;
    justify-content: center;
}

#main_delay_popup_image {
    width: 318px;
    height: 419px;
}

#main_delay_popup_image2 {
    position: absolute;

    top: 277px;
    left: 50%;
    transform: translateX(-50%);

    width: 203px;
    height: 73px;
}

#main_delay_popup_image3 {
    width: calc(318px - 40px);
    height: calc(300px - 40px);
    padding: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: 'APPLE';
    font-size: 16px;
    color: #5533d4;
}

#main_delay_popup_buttons2 {
    cursor: pointer;
    width: 120px;
    height: 35px;
    border-radius: 5px;
    background-color: #ffa500;

    margin-top: 38px;
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: 'APPLE';
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
}

#main_delay_popup_buttons {
    background-color: white;
    width: 100%;
    height: 39px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

#main_delay_popup_button1 {
    cursor: pointer;
    margin-left: 19px;
    height: 100%;

    display: flex;
    align-items: center;

    font-family: 'APPLE';
    font-size: 14px;
    text-align: center;
    color: #a0a0a0;

    text-decoration: underline;
}

#main_delay_popup_button2 {
    cursor: pointer;
    margin-right: 19px;
    height: 100%;

    display: flex;
    align-items: center;

    font-family: 'APPLE';
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    color: #ffa500;
}

@media (max-width: 1000px) {
    body {
        margin: 0px;
        width: 100vw;
        background-color: #ffffff;
    }

    .main_container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    #main_card_wrapper {
        left: 0%;
        transform: translateX(0%);
        width: 100%;
        height: 100%;
    }

    .main_card {
        width: 100%;
        height: 100%;
    }

    #main_delay_popup_buttons {
        background-color: white;
        width: 100%;
        height: 39px;

        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #main_delay_popup_button1 {
        cursor: pointer;
        margin-left: 19px;
        height: 100%;

        display: flex;
        align-items: center;

        font-family: 'APPLE';
        font-size: 14px;
        text-align: center;
        color: #a0a0a0;

        text-decoration: underline;
    }

    #main_delay_popup_button2 {
        cursor: pointer;
        margin-right: 19px;
        height: 100%;

        display: flex;
        align-items: center;

        font-family: 'APPLE';
        font-size: 14px;
        text-align: center;
        font-weight: bold;
        color: #ffa500;
    }

    #main_card_pager_wrapper {
        position: absolute;
        right: 7px;
        top: 50%;
        transform: translateY(-50%);
    }

    #main_card_scrollguide {
        display: none;
    }

    #main_card_dot {
        left: calc(50% - 40px);
        top: calc(50% - 40px);

        width: 80px;
        height: 80px;

        border-radius: 40px;
    }

    #main_card_dot_text_wrapper {
        left: calc(50% - 40px);
        top: calc(50% - 40px);

        width: 80px;
        height: 80px;

        border-radius: 40px;
    }

    #main_card_dot_text {
        font-size: 14px;
        height: 34px;
    }

    #main_card_dot_hand_wrapper {
        left: calc(50% - 40px);
        top: calc(50% - 40px);
        width: 80px;
        height: 80px;
    }

    #main_card_dot_hand {
        width: 17px;
        height: 27px;
    }
}
